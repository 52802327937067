import React, { useState } from "react";
import { Modal, Upload } from "antd";
import { ConditionalRendering, getBase64, handleError, isImage, openFileInBrowser } from "../../utils/methods";
import { IMAGE_FILETYPE, PDF_FILETYPE } from "../../utils/constants";
import { InboxOutlined } from "@ant-design/icons";
import { AiOutlineDelete } from "react-icons/ai";
import { FileIcon } from "../../assets/svg";
const { Dragger } = Upload;

const UploadForm = ({ isOpen, setIsOpen, loading, uploadKYCFunc }) => {

    const [fileObj, setFileObj] = useState({});

    const handleUpload = async (file) => {
        if (file?.type.search(/(jpg|jpeg|png|pdf)/) !== -1) {
            if (file && file?.thumbUrl && file?.thumbUrl?.length) {
                setFileObj(file);
            } else {
                await getBase64(file).then((uri) => {
                    file.thumbUrl = uri;
                    setFileObj(file);
                })
            }
        } else {
            handleError('Only PDF, JPG, JPEG and PNG files are supported')
        }
    };

    const onChange = (info) => {
        const { status } = info.file;
        if (status !== 'uploading') {
            handleUpload(info?.file?.originFileObj)
        }
    }

    const renderImage = () => {
        try {
            if (fileObj && Object.keys(fileObj)?.length) {
                return (
                    <div className="c-preview-list">
                        <div className="preview flex0 margin-right_zero">
                            <div
                                className="left"
                                onClick={() => openFileInBrowser(fileObj, false)}
                            >
                                <div className="thumbnail">
                                    {isImage(fileObj) ? <img src={fileObj?.thumbUrl} /> : <FileIcon />}
                                </div>
                                <span>
                                    <div>{fileObj?.name}</div>
                                </span>
                            </div>
                            <ConditionalRendering
                                condition={!loading}
                                children={<AiOutlineDelete
                                    className="c-icon"
                                    onClick={() => setFileObj({})}
                                />}
                            />
                        </div>
                    </div>
                );
            } else {
                return null;
            }
        } catch (e) {
            console.log(e);
        }
    };

    const onCancel = () => {
        if (!loading) {
            setIsOpen(false);
            setFileObj({});
        }
    }

    return (
        <Modal
            title={"Upload KYC"}
            width={450}
            centered
            open={isOpen}
            maskClosable={false}
            onCancel={onCancel}
            okButtonProps={{ title: 'Submit', onClick: () => uploadKYCFunc(fileObj), disabled: !(fileObj && Object.keys(fileObj)?.length), loading: loading }}
            okText='Submit'
            cancelButtonProps={{ hidden: true }}
            destroyOnClose
        >
            <Dragger
                onChange={onChange}
                accept={`${IMAGE_FILETYPE},${PDF_FILETYPE}`}
                showUploadList={false}
                action={undefined}
                disabled={(fileObj && Object.keys(fileObj)?.length)} >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">Only Image and PDF file type can be uploaded.</p>
            </Dragger>
            {renderImage()}
        </Modal>
    );
};
export default React.memo(UploadForm);