import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import OtpInput from "react-otp-input";
import { Form, Formik } from "formik";
import Validation from "./Validations";
import { CButton, CField } from "../../uiComponents";

const OTPConfirmation = ({ isOpen, setIsOpen, onSubmitOTP, loading, title }) => {

    return (
        <Modal
            title={title || "Enter OTP to Verify"}
            width={450}
            centered
            open={isOpen}
            maskClosable={false}
            closable={false}
            onCancel={() => setIsOpen(false)}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose
        >
            <Formik
                validationSchema={Validation}
                validateOnChange={true}
                validateOnBlur={true}
                initialValues={{}}
                onSubmit={onSubmitOTP}
            >
                {({ errors, touched, handleSubmit, setFieldValue, values }) => (
                    <Form>
                        <CField
                            className="otp-container"
                            name="otp"
                            error={errors?.otp}
                            touched={touched?.otp}
                            disabled={loading}
                            customField={() => <OtpInput
                                className="otp-field"
                                isDisabled={loading}
                                value={values?.otp}
                                onChange={(val) => setFieldValue('otp', val)}
                                numInputs={6}
                            />}
                        />

                        <CButton
                            containerClass="flex0"
                            onClick={handleSubmit}
                            type="submit"
                            htmlType="submit"
                            title={'Submit'}
                            loading={loading}
                            disabled={loading}
                        />
                    </Form>)}
            </Formik>
        </Modal>
    );
};
export default React.memo(OTPConfirmation);