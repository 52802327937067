import React, { useEffect, useState, memo } from "react";
import { useLocation, useNavigate } from "react-router";
import { PageWrapper } from "../../../containers";
import { CButton } from "../../../uiComponents";
import { _readableTextCap, getQueryFiltersAsObject, pushFiltersToQueryString, readableText, renderCheckboxFilter } from "../../../utils/methods";
import { useDispatch, useSelector } from "react-redux";
import { Popconfirm, Tag, Table } from "antd";
import { deleteBeneficiary, getBeneficiaries } from "../../../store/actions/FindCardHolder.action";
import { BENFICIARY_TRANSACTION_TYPE } from "../../../utils/constants";
import Add from "./Add";
import UpdateForm from "./UpdateForm";

function Beneficiaries() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const employee = location.state?.employee;
  const client = location.state?.client;

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [selected, setSelected] = useState(null);
  const [isInitial, setIsInitial] = useState(true);
  const [limit] = useState(10);
  const [allFilter, setAllFilter] = useState({});

  const reduxState = useSelector(({ findCardHolder, auth }) => {
    return {
      user: auth.user,
      data: findCardHolder.beneficiaries,
      metaData: findCardHolder.beneficiariesMetaData,
      loading: findCardHolder.getBeneficiariesLoading,
      deleteLoading: findCardHolder.deleteBeneficiaryLoading,
    };
  });

  const { data, metaData, loading, deleteLoading } = reduxState;

  useEffect(() => {
    let filters = getQueryFiltersAsObject(location, true);
    if (Object.keys(filters)?.length) {
      setAllFilter(filters);
    } else {
      pushFilter();
    }
    setIsInitial(false);
  }, []);

  useEffect(() => {
    if (!isInitial) {
      pushFilter();
    }
  }, [allFilter]);

  const get = () => {
    let payload = {
      ...allFilter,
      page: allFilter?.page || 1,
      limit: limit,
      client: client?._id,
      employee: employee?._id,
      transactionTypes: ['REMITTANCE']
    };
    dispatch(getBeneficiaries(payload));
  };

  // push filters on queryString
  const pushFilter = async () => {
    let payload = {
      ...allFilter,
      page: allFilter?.page || 1,
    };
    await pushFiltersToQueryString(payload, navigate, location);
    get();
  };

  const toggleUpdateModal = (value = false, obj = {}) => {
    setIsOpenUpdate(value);
    setSelected(obj);
  };

  const headerProps = {
    renderRight: () => renderRight(),
    title: "Beneficiaries",
    subTitle: "Find Card Holder",
    canGoBack: true,
  };

  const renderRight = () => {
    return (
      <CButton
        buttonLayout="primary-outline"
        disabled={loading || deleteLoading}
        title="Add Beneficiary"
        onClick={() => setIsOpen(true)}
      />
    );
  };

  const COLUMNS = [
    {
      title: "Alias",
      dataIndex: "beneficiaryAlias",
      width: 80,
    },
    {
      title: "Transaction Type",
      dataIndex: "transactionType",
      render: (val) => <div className="bold-text ">{_readableTextCap(val)}</div>,
      width: 80,
      // ...renderCheckboxFilter('transactionTypes', allFilter, setAllFilter, BENFICIARY_TRANSACTION_TYPE, '_id', 'name'),
    },
    {
      title: "vendor",
      dataIndex: "vendor",
      width: 80,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        return (
          <Tag color={status === "ACTIVE" ? 'green' : 'red'}>
            {readableText(status)}
          </Tag>
        )
      },
      width: 60,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (val, obj) => (
        <div className="inner-data no-wrap">
          <CButton
            buttonLayout="green-outline small"
            title="Edit"
            disabled={deleteLoading}
            onClick={() => toggleUpdateModal(true, obj)}
          />
          <Popconfirm
            title="Are you sure, do you want to delete?"
            onConfirm={() => dispatch(deleteBeneficiary({ id: obj?._id }, pushFilter))}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <CButton
              containerClass="flex0"
              buttonLayout="red-outline small"
              title="Delete"
              disabled={deleteLoading}
            />
          </Popconfirm>
        </div>
      ),
      width: 160,
    },
  ];

  return (
    <PageWrapper headerProps={headerProps}>
      <Table
        className={`c-table layout-auto ${data?.length ? "" : "no-data-table"}`}
        rowKey={(obj) => obj?._id}
        scroll={{ x: "100%" }}
        loading={loading}
        columns={COLUMNS}
        dataSource={data}
        pagination={{
          total: metaData?.totalDocuments,
          pageSize: limit,
          showSizeChanger: false,
          current: metaData?.page,
          defaultCurrent: allFilter?.page,
          onChange: (page) => setAllFilter({ ...allFilter, page }),
        }}
      />

      <Add isOpen={isOpen} setIsOpen={setIsOpen} get={get} client={client} employee={employee} />
      <UpdateForm isOpen={isOpenUpdate} setIsOpen={setIsOpenUpdate} selected={selected} get={get} />

    </PageWrapper>
  );
}

export default memo(Beneficiaries);