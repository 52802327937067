import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CButton } from "../../uiComponents";
import { updateMobileUserPassword, updateMobileUserPasswordOTP } from "../../store/actions/Employees.action";
import { MdOutlinePassword } from "react-icons/md";
import { ConditionalRendering } from "../../utils/methods";
import OTPConfirmation from "./OTPConfirmation";
import { MOBILE_USER_STATUS } from "../../utils/constants";

const CUpdateMobileUserPassword = ({ employee, client, mobileUser }) => {

    const dispatch = useDispatch();

    const reduxState = useSelector(({ employees }) => {
        return {
            otpLoading: employees.updateMobileUserPasswordOTPLoading,
            holdingResponse: employees.updateMobileUserPasswordOTPHoldingResponse,
            updateLoading: employees.updateMobileUserPasswordLoading,

        };
    });

    const { otpLoading, holdingResponse, updateLoading } = reduxState;

    const [isOpen, setIsOpen] = useState(false);

    const callBack = (res) => {
        if (res?.mobileAppToken) {
            setIsOpen(true);
        }
    }

    const getOTP = () => {
        const payload = {
            employee: employee?._id,
            client: client?._id,
        }
        dispatch(updateMobileUserPasswordOTP(payload, callBack));
    }

    const updateUserCallBack = () => {
        setIsOpen(false);
    }

    const updateMobileAppUserPassword = (values) => {
        const payload = {
            employee: employee?._id,
            client: client?._id,
            mobileAppToken: holdingResponse?.mobileAppToken,
            otp: values?.otp,
        }
        dispatch(updateMobileUserPassword(payload, updateUserCallBack));
    }

    return <>
        <ConditionalRendering
            condition={mobileUser?.status === MOBILE_USER_STATUS?.ACTIVE}
            children={<CButton
                containerClass="flex0"
                buttonType="gradient"
                title="Update Mobile User Password"
                disabled={updateLoading || otpLoading}
                loading={updateLoading || otpLoading}
                onClick={getOTP}
                leftIcon={(className) => <MdOutlinePassword className={className} />}
            />}
        />
        <OTPConfirmation title='Enter OTP to verify user' isOpen={isOpen} setIsOpen={setIsOpen} onSubmitOTP={updateMobileAppUserPassword} loading={otpLoading || updateLoading} />
    </>
};

export default CUpdateMobileUserPassword;