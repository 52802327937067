const EMPLOYEES = {
  GET_EMPLOYEES: "GET_EMPLOYEES",
  UPLOAD_EMPLOYEE: "UPLOAD_EMPLOYEE",
  UPLOAD_OTHER_BANK_EMPLOYEES: "UPLOAD_OTHER_BANK_EMPLOYEES",
  ADD_EMPLOYEE: "ADD_EMPLOYEE",
  UPDATE_EMPLOYEE: "UPDATE_EMPLOYEE",
  EDIT_EMPLOYEE: "EDIT_EMPLOYEE",

  BLOCK_ACTIVE_REQUEST: "BLOCK_ACTIVE_REQUEST",
  GET_EMPLOYEE_DETAILS: "GET_EMPLOYEE_DETAILS",
  DOWNLOAD_LIST: "DOWNLOAD_LIST",
  CHANGE_BANK_TYPE: "CHANGE_BANK_TYPE",

  GET_EMPLOYEE_TRANSACTION: "GET_EMPLOYEE_TRANSACTION",

  BLOCK_APP: "BLOCK_APP",
  ACTIVATE_APP: "ACTIVATE_APP",
  REPLACE_CARD: "REPLACE_CARD",

  GET_EMPLOYEE_TRANSACTION_OTHER_BANK: "GET_EMPLOYEE_TRANSACTION_OTHER_BANK",

  GET_BALANCE: "GET_BALANCE",
  CHECK_CARD_STATUS: "CHECK_CARD_STATUS",
  GET_APP_TRANSACTIONS: "GET_APP_TRANSACTIONS",

  SYNC_EMPLOYEES: "SYNC_EMPLOYEES",

  CHANGE_PIN: "CHANGE_PIN",
  UPDATE_EID: "UPDATE_EID",

  GET_KAMELPAY_EMPLOYEES_TEMPLATE: "GET_KAMELPAY_EMPLOYEES_TEMPLATE",

  EMPLOYEE_STATEMENT_REQUEST: "EMPLOYEE_STATEMENT_REQUEST",

  DELET_DUPLICATION: "DELET_DUPLICATION",

  CARD_ACTIVATE_OR_BLOCK: "CARD_ACTIVATE_OR_BLOCK",

  OCR_EID: "OCR_EID",

  GET_EID_FULL_DETAIL: "GET_EID_FULL_DETAIL",

  UPDATE_DIRECT_EID: "UPDATE_DIRECT_EID",

  INACTIVE_EMPLOYEES: "INACTIVE_EMPLOYEES",

  CHECK_SUBSCRIPTION_STATUS: "CHECK_SUBSCRIPTION_STATUS",

  WHITELIST_BLACKLIST: "WHITELIST_BLACKLIST",

  SWITCH_TO_KAMELPAY: "SWITCH_TO_KAMELPAY",

  CREATE_MOBILE_APP_USER: "CREATE_MOBILE_APP_USER",

  RESEND_OTP_MOBILE_APP_USER: "RESEND_OTP_MOBILE_APP_USER",

  VERIFY_OTP_MOBILE_USER: "VERIFY_OTP_MOBILE_USER",

  GET_OCR_TOKEN: "GET_OCR_TOKEN",

  UPLOAD_UQUDO_IMAGES: "UPLOAD_UQUDO_IMAGES",

  CHECK_ADVANCE_SALARY_ELIGIBILITY: "CHECK_ADVANCE_SALARY_ELIGIBILITY",

  APPLY_ADVANCE_SALARY: "APPLY_ADVANCE_SALARY",

  APPLY_PROMO_AND_OFFER: "APPLY_PROMO_AND_OFFER",

  VERIFY_OTP_ADV_SALARY: "VERIFY_OTP_ADV_SALARY",

  RESEND_OTP_ADV_SALARY: 'RESEND_OTP_ADV_SALARY',

  VERIFY_ADV_SALARY: "VERIFY_ADV_SALARY",

  UPDATE_DOB: "UPDATE_DOB",

  UPDATE_MOBILE_USER_PASSWORD_OTP: "UPDATE_MOBILE_USER_PASSWORD_OTP",
  UPDATE_MOBILE_USER_PASSWORD: "UPDATE_MOBILE_USER_PASSWORD",

  UPDATE_MOBILE_USER_PHONE_OTP: "UPDATE_MOBILE_USER_PHONE_OTP",
  UPDATE_MOBILE_USER_PHONE: "UPDATE_MOBILE_USER_PHONE",

  UPLOAD_KYC: 'UPLOAD_KYC',
  
  GET_OTP_BYPASS_EID: 'GET_OTP_BYPASS_EID',
  LIGHT_USER_TO_PORTAL_USER: 'LIGHT_USER_TO_PORTAL_USER',

  ADD_PASSPORT: "ADD_PASSPORT",

  PROCESS_BULK_DEACTIVATION_FILE: "PROCESS_BULK_DEACTIVATION_FILE",

  BULK_EMPLOYEES_DEACTIVATION: "BULK_EMPLOYEES_DEACTIVATION",

};

export default EMPLOYEES;
