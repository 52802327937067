import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import OtpInput from "react-otp-input";
import { Form, Formik } from "formik";
import { reSendOTPAdvanceSalary, verifyOTPAdvSalary } from "../../store/actions/Employees.action";
import { useDispatch, useSelector } from "react-redux";
import { OTPScheme } from "./Validations";
import { CButton, CField } from "../../uiComponents";

const OTPConfirmation = ({ isOpen, setIsOpen, employeeId, client, holdingAdvSalaryRes, callBack }) => {

    const dispatch = useDispatch();

    const reduxState = useSelector(({ employees }) => {
        return {
            loading: employees.verifOTPAdvSalaryLoading || employees.verifyAdvSalaryLoading,
            fullLoading: employees.verifOTPAdvSalaryLoading || employees.verifyAdvSalaryLoading || employees.resendOTPAdvSalaryLoading
        };
    });

    const { loading, fullLoading } = reduxState;

    const [counter, setcounter] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            if (counter > 0) {
                setcounter(counter - 1);
            }
        }, 1000);
    }, [counter]);

    const onFinish = (values) => {
        const payload = {
            client: client,
            employeeId: employeeId,
            otp: values?.otp,
            token: holdingAdvSalaryRes?.token
        }
        dispatch(verifyOTPAdvSalary(payload, callBack))
    }

    const onResendCallback = () => {
        setcounter(30);
    }

    const onResend = () => {
        if (!fullLoading) {
            let payload = {
                client: client?._id,
                employeeId: employeeId,
                token: holdingAdvSalaryRes?.token
            }
            dispatch(reSendOTPAdvanceSalary(payload, onResendCallback));
        }
    }

    return (
        <Modal
            title="Enter OTP to Verify Mobile User"
            width={450}
            centered
            open={isOpen}
            maskClosable={false}
            closable={false}
            onCancel={() => setIsOpen(false)}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose
        >
            <Formik
                validationSchema={OTPScheme}
                validateOnChange={true}
                validateOnBlur={true}
                initialValues={{}}
                onSubmit={onFinish}
            >
                {({ errors, touched, handleSubmit, setFieldValue, values }) => (
                    <Form>
                        <CField
                            className="otp-container"
                            name="otp"
                            error={errors?.otp}
                            touched={touched?.otp}
                            customField={() => <OtpInput
                                className="otp-field"
                                value={values?.otp}
                                isDisabled={fullLoading}
                                onChange={(val) => setFieldValue('otp', val)}
                                numInputs={6}
                            />}
                        />

                        <CButton
                            containerClass="flex0"
                            onClick={handleSubmit}
                            type="submit"
                            htmlType="submit"
                            title={'Submit'}
                            loading={loading}
                            disabled={fullLoading}
                        />
                    </Form>)}
            </Formik>
            <div style={{ fontSize: 12 }} >
                {counter < 1 ?
                    <div style={{ textAlign: 'center' }} >
                        <span>Didn't Receive Code?</span> <span style={{ color: '#4A58D1', cursor: fullLoading ? 'default' : 'pointer', opacity: fullLoading ? .6 : 1 }} onClick={onResend}>Resend </span>
                    </div> :
                    <div style={{ textAlign: 'center' }} >00:{counter.toString().padStart(2, '0')}</div>}
            </div>
        </Modal>
    );
};
export default React.memo(OTPConfirmation);