import TransactionsHistoryConstant from "../constants/TransactionsHistory.contant";

const initialState = {
    history: [],
    getHistoryLoading: false,
    historyMetaData: {},
    stats : [],
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case TransactionsHistoryConstant.GET_TRANSACTIONS_HISTORY:
            return {
                ...state,
                history: action.data,
                getHistoryLoading: action.loading,
                historyMetaData: action.metaData,
                stats: action.statsCount,
            };

        default:
            return state;
    }
};