import { get, post } from "../../utils/apiMethods";
import FindCardHolderConstant from "../constants/FindCardHolder.constant";
import { encodeQueryData, handleError, handleSuccess } from "../../utils/methods";

export const getSingleEmployee = (payload, CB) => async (dispatch) => {
  let dispatchType = FindCardHolderConstant.GET_EMPLOYEE;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/getEmployeesByCard`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false, data: data?.data });
        CB && CB(data?.data);
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
      dispatch({ type: dispatchType, loading: false });
    });
};

export const clearSingleEmployee = () => async (dispatch) => {
  let dispatchType = FindCardHolderConstant.GET_EMPLOYEE;
  dispatch({ type: dispatchType, loading: false, data: {} });
};

export const getBeneficiaries = (payload) => async (dispatch) => {
  let dispatchType = FindCardHolderConstant.GET_BENEFICIARIES;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/getBeneficiary`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false, data: data?.data?.data, metaData: data?.data?.metaData, });
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
      dispatch({ type: dispatchType, loading: false });
    });
};

export const getBeneficiaryCountries = (payload) => async (dispatch, getState) => {
  const isLoadMore = payload?.page > 1;
  let dispatchType = FindCardHolderConstant.GET_BENEFICIARY_COUNTRIES;
  if (isLoadMore) {
    dispatchType = FindCardHolderConstant.LOAD_MORE_BENEFICIARY_COUNTRIES;
  }
  let queryParams = encodeQueryData(payload) ? `?${encodeQueryData(payload)}` : '';
  dispatch({ type: dispatchType, loading: true, canLoadMore: true, data: [], metaData: {} });
  get(`employees/getBeneficiariesCountries${queryParams}`)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({
          type: dispatchType,
          loading: false,
          data: data?.data?.data,
          metaData: data?.data?.metaData,
          canLoadMore: data?.data?.metaData?.totalDocuments > data?.data?.data?.length,
          specialCountries: data?.data?.specialCountries,
        });
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
      dispatch({ type: dispatchType, loading: false });
    });
};

export const getTypesAndBanks = (payload) => async (dispatch) => {
  let dispatchType = FindCardHolderConstant.GET_TYPES_AND_BANKS;
  let queryParams = encodeQueryData(payload) ? `?${encodeQueryData(payload)}` : '';
  dispatch({ type: dispatchType, loading: true });
  get(`employees/getTypesAndBanks${queryParams}`)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false, bankTypes: data?.data?.BankType, specialBanks: data?.data?.SpecialBanks });
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
      dispatch({ type: dispatchType, loading: false });
    });
};

// export const selectBeneficiaryCountry = (payload) => async (dispatch) => {
//   const dispatchType = FindCardHolderConstant.SELECT_BENEFICIARY_COUNTRY;
//   dispatch({ type: dispatchType, data: payload });
// };

export const getBanks = (payload) => async (dispatch) => {
  const isLoadMore = payload?.page > 1;
  let dispatchType = FindCardHolderConstant.GET_BANKS;
  if (isLoadMore) {
    dispatchType = FindCardHolderConstant.LOAD_MORE_BANKS;
  }
  let queryParams = encodeQueryData(payload) ? `?${encodeQueryData(payload)}` : '';
  dispatch({ type: dispatchType, loading: true, canLoadMore: true, data: [], metaData: {} });
  get(`employees/getBanks${queryParams}`)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({
          type: dispatchType,
          loading: false,
          data: data?.data?.data,
          metaData: data?.data?.metaData,
          canLoadMore: data?.data?.metaData?.totalDocuments > data?.data?.data?.length,
        });
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
      dispatch({ type: dispatchType, loading: false });
    });
};

// export const selectBank = (payload) => async (dispatch) => {
//   const dispatchType = FindCardHolderConstant.SELECTED_BANK;
//   dispatch({ type: dispatchType, data: payload });
// }

export const getBranches = (payload, CB) => async (dispatch) => {
  const isLoadMore = payload?.page > 1;
  let dispatchType = FindCardHolderConstant.GET_BRANCHES;
  if (isLoadMore) {
    dispatchType = FindCardHolderConstant.LOAD_MORE_BRANCHES;
  }
  let queryParams = encodeQueryData(payload) ? `?${encodeQueryData(payload)}` : '';
  dispatch({ type: dispatchType, loading: true, canLoadMore: true, data: [], metaData: {} });
  get(`employees/getBankBranches${queryParams}`)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({
          type: dispatchType,
          loading: false,
          data: data?.data?.data,
          metaData: data?.data?.metaData,
          canLoadMore: data?.data?.metaData?.totalDocuments > data?.data?.data?.length,
        });
        CB && CB(data?.data?.data);
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
      dispatch({ type: dispatchType, loading: false });
    });
};

export const addbeneficiary = (payload, CB) => async (dispatch) => {
  const dispatchType = FindCardHolderConstant.ADD_BENEFICIARY;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/addBeneficiary`, payload)
    .then(async ({ data }) => {
      if (data?.error) {
        dispatch({ type: dispatchType, loading: false });
        handleError(data?.data?.message || 'Something went wrong!');
      } else {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.message || 'Successfully Employer File Uploaded');
        CB && CB();
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || 'Something went wrong!');
    });
};

export const deleteBeneficiary = (payload, CB) => async (dispatch) => {
  const dispatchType = FindCardHolderConstant.DELETE_BENEFICIARY;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/deleteBeneficiary`, payload)
    .then(async ({ data }) => {
      if (data?.error) {
        dispatch({ type: dispatchType, loading: false });
        handleError(data?.data?.message || 'Something went wrong!');
      } else {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.message || 'Successfully Employer File Uploaded');
        CB && CB();
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || 'Something went wrong!');
    });
};

export const editBeneficiary = (payload, CB) => async (dispatch) => {
  const dispatchType = FindCardHolderConstant.EDIT_BENEFICIARY;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/editBeneficiary`, payload)
    .then(async ({ data }) => {
      if (data?.error) {
        dispatch({ type: dispatchType, loading: false });
        handleError(data?.data?.message || 'Something went wrong!');
      } else {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.message || 'Successfully Employer File Uploaded');
        CB && CB();
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || 'Something went wrong!');
    });
};

export const getBankByIBANorIFSC = (payload, CB) => async (dispatch) => {
  const dispatchType = FindCardHolderConstant.GET_BANK_BY_IBAN_IFSC;
  dispatch({ type: dispatchType, loading: true });
  let queryParams = encodeQueryData(payload) ? `?${encodeQueryData(payload)}` : '';
  get(`employees/getBankBranch${queryParams}`)
    .then(async ({ data }) => {
      if (data?.error) {
        dispatch({ type: dispatchType, loading: false });
        handleError(data?.data?.message || 'Something went wrong!');
      } else {
        dispatch({ type: dispatchType, loading: false });
        CB && CB(data?.data?.data);
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || 'Something went wrong!');
    });
};

export const getOTPBeneficiary = (payload, CB) => async (dispatch) => {
  const dispatchType = FindCardHolderConstant.GET_OTP_BENEFICIARY;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/addBeneficiaryGenerateOTP`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.message);
        CB && CB({ token: data?.data?.mobileAppToken, userId: data?.data?.userId });
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const reSendOTPBeneficiary = (payload, CB) => async (dispatch) => {
  const dispatchType = FindCardHolderConstant.RESEND_OTP_BENEFICIARY;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/resendBeneficiaryOTP `, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.message);
        CB && CB({ token: data?.data?.mobileAppToken, userId: data?.data?.userId });
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};