import React, { useEffect, useState } from "react";
import { CButton, CField } from "..";
import { Modal } from "antd";
import OtpInput from "react-otp-input";
import { Form, Formik } from "formik";
import { reSendMobileAppUser, verifyMobileAppUser } from "../../store/actions/Employees.action";
import { useDispatch, useSelector } from "react-redux";
import scheme from "./Validations";

const AddOTPMobileUser = ({ isOpen, setIsOpen, employeeId, clientId, getOCRTokenFunc }) => {

    const dispatch = useDispatch();

    const reduxState = useSelector(({ employees }) => {
        return {
            loading: employees.createMobileAppUserLoading || employees.verifyMobileAppUserLoading || employees.getOCRTokenLoading || employees.resendOTPMobileUserLoading,
            response: employees.holdingMobileUserData,
        };
    });

    const { loading, response } = reduxState;

    const [counter, setcounter] = useState(0);
    const [resendRes, setResendRes] = useState({});

    useEffect(() => {
        setTimeout(() => {
            if (counter > 0) {
                setcounter(counter - 1);
            }
        }, 1000);
    }, [counter]);

    const onResend = () => {
        let payload = {
            client: clientId,
            employeeId: employeeId,
            id: response?.data?._id || response?._id,
        }
        dispatch(reSendMobileAppUser(payload, setResendRes));
        setcounter(59);
    }

    const verifyOTPMobileUserFunc = (values) => {
        const payload = {
            client: clientId,
            employeeId: employeeId,
            otp: values?.otp,
            id: response?.data?._id || response?._id,
            token: resendRes?.token || response?.token
        }
        dispatch(verifyMobileAppUser(payload, getOCRTokenFunc))
    }

    return (
        <Modal
            title="Enter OTP to Verify Mobile User"
            width={450}
            centered
            open={isOpen}
            maskClosable={false}
            closable={false}
            keyboard={false}
            onCancel={() => setIsOpen(false)}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose
        >
            <Formik
                validationSchema={scheme}
                validateOnChange={true}
                validateOnBlur={true}
                initialValues={{}}
                onSubmit={verifyOTPMobileUserFunc}
            >
                {({ errors, touched, handleSubmit, setFieldValue, values }) => (
                    <Form>
                        <CField
                            className="otp-container"
                            name="otp"
                            error={errors?.otp}
                            touched={touched?.otp}
                            customField={() => <OtpInput
                                className="otp-field"
                                value={values?.otp}
                                onChange={(val) => setFieldValue('otp', val)}
                                numInputs={6}
                            />}
                        />

                        <CButton
                            containerClass="flex0"
                            onClick={handleSubmit}
                            type="submit"
                            htmlType="submit"
                            title={'Submit'}
                            loading={loading}
                            disabled={loading}
                        />
                    </Form>)}
            </Formik>
            <div style={{ fontSize: 12 }} >
                {counter < 1 ?
                    <div style={{ textAlign: 'center' }} >
                        <span>Didn't Receive Code?</span> <span style={{ color: '#4A58D1', cursor: 'pointer' }} onClick={onResend}>Resend </span>
                    </div> :
                    <div style={{ textAlign: 'center' }} >00:{counter.toString().padStart(2, '0')}</div>}
            </div>
        </Modal>
    );
};
export default React.memo(AddOTPMobileUser);