import MARKETPLACE from "../constants/Marketplace.constant";


const initialState = {

    allCategories: [],
    getAllCategoriesLoading: false,

    categories: [],
    getCategoriesLoading: false,
    categoriesMetadata: {},

    addCategoryLoading: false,

    allSubCategories: [],
    getAllSubCategoriesLoading: false,

    subCategories: [],
    getSubcategoriesLoading: false,
    subCategoriesMetadata: {},

    addSubCategoryLoading: false,

    products: [],
    getProductsLoading: false,
    productsMetadata: {},

    addProductLoading: false,

    allSuppliers: [],
    getAllSuppliersLoading: false,

    suppliers: [],
    getSuppliersLoading: false,
    suppliersMetadata: {},

    addSupplierLoading: false,

    createProductVariationsLoading: false,

    addProductImagesLoading: false,

    product: {},
    getProductLoading: false,

    orders: [],
    getOrdersLoading: false,
    ordersMetadata: {},

    markDeliveredToOrderProductLoading: false,

    addTrackingIdToOrderProductLoading: false,

    assignSupplierToOrderProductLoading: false,

    suppliersByProduct: [],
    getSuppliersByProductLoading: false,

    productInventories: [],
    getProductInventoriesLoading: false,

    updateProductInventoryLoading: false,
};

export default (state = initialState, action = {}) => {
    switch (action.type) {

        case MARKETPLACE.GET_ALL_CATEGORIES:
            return {
                ...state,
                allCategories: action.data,
                getAllCategoriesLoading: action.loading,
            };
        case MARKETPLACE.GET_CATEGORIES:
            return {
                ...state,
                categories: action.data,
                getCategoriesLoading: action.loading,
                categoriesMetadata: action.metaData,
            };
        case MARKETPLACE.ADD_CATEGORY:
            return {
                ...state,
                addCategoryLoading: action.loading,
            };

        case MARKETPLACE.GET_ALL_SUB_CATEGORIES:
            return {
                ...state,
                allSubCategories: action.data,
                getAllSubCategoriesLoading: action.loading,
            };
        case MARKETPLACE.GET_SUB_CATEGORIES:
            return {
                ...state,
                subCategories: action.data,
                getSubcategoriesLoading: action.loading,
                subCategoriesMetadata: action.metaData,
            };
        case MARKETPLACE.ADD_SUB_CATEGORY:
            return {
                ...state,
                addSubCategoryLoading: action.loading,
            };

        case MARKETPLACE.GET_PRODUCTS:
            return {
                ...state,
                products: action.data || state.products,
                getProductsLoading: action.loading,
                productsMetadata: action.metaData,
            };
        case MARKETPLACE.ADD_PRODUCT:
            return {
                ...state,
                addProductLoading: action.loading,
            };

        case MARKETPLACE.GET_ALL_SUPPLIERS:
            return {
                ...state,
                allSuppliers: action.data,
                getAllSuppliersLoading: action.loading,
            };
        case MARKETPLACE.GET_SUPPLIERS:
            return {
                ...state,
                suppliers: action.data || state.suppliers,
                getSuppliersLoading: action.loading,
                suppliersMetadata: action.metaData,
            };
        case MARKETPLACE.ADD_SUPPLIER:
            return {
                ...state,
                addSupplierLoading: action.loading,
            };
         case MARKETPLACE.CREATE_PRODUCT_VARIATIONS:
            return {
                        ...state,
                        createProductVariationsLoading: action.loading,
                    };
        case MARKETPLACE.ADD_PRODUCT_IMAGES:
            return {
                    ...state,
                    addProductImagesLoading: action.loading,
                };

        case MARKETPLACE.GET_PRODUCT_DETAIL:
            return {
                    ...state,
                    product: action.data,
                    getProductLoading: action.loading,
                };

        case MARKETPLACE.GET_ORDERS:
            return {
                ...state,
                orders: action.data || state.orders,
                getOrdersLoading: action.loading,
                ordersMetadata: action.metaData,
            };
        case MARKETPLACE.MARK_DELIVERED_TO_ORDER_PRODUCT:
            return {
                ...state,
                markDeliveredToOrderProductLoading: action.loading,
            };
        case MARKETPLACE.ADD_TRACKING_ID_TO_ORDER_PRODUCT:
            return {
                ...state,
                addTrackingIdToOrderProductLoading: action.loading,
            };
        case MARKETPLACE.ASSIGN_SUPPLIER_TO_ORDER_PRODUCT:
            return {
                ...state,
                assignSupplierToOrderProductLoading: action.loading,
            };

        case MARKETPLACE.GET_SUPPLIERS_BY_PRODUCT:
            return {
                ...state,
                suppliersByProduct: action.data,
                getSuppliersByProductLoading: action.loading,
            };

        case MARKETPLACE.GET_PRODUCT_INVENTORIES:
            return {
                ...state,
                productInventories: action.data,
                pendingProductOrders: action.pendingProductOrders,
                totalAvailableUnits: action.totalAvailableUnits,
                totalProductsSold: action.totalProductsSold,
                getProductInventoriesLoading: action.loading,
            };

        case MARKETPLACE.UPDATE_PRODUCT_INVENTORY:
            return {
                ...state,
                updateProductInventoryLoading: action.loading,
            };

        default:
            return state;
    }
};
