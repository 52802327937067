const APPROVAL = {
    GET_APPROVALS: 'GET_APPROVALS',
    APPROVE_REJECT: 'APPROVE_REJECT',
    GET_EMPLOYEERS_LIST: 'GET_EMPLOYEERS_LIST',
    GET_SINGLE_APPROVAL: 'GET_SINGLE_APPROVAL',
    UPDATE_APPROVAL_SOCKET: 'UPDATE_APPROVAL_SOCKET',
    PROCESS_EPLOYEES_FILE: 'PROCESS_EPLOYEES_FILE',
    INSERT_BULK_EMPLOYEES: 'INSERT_BULK_EMPLOYEES',
    GET_BULK_EMPLOYEES_FROM_APPROVAL: 'GET_BULK_EMPLOYEES_FROM_APPROVAL',
    UPDATE_PENDING_BULK_EMPLOYEES: 'UPDATE_PENDING_BULK_EMPLOYEES',
    FORCE_APPROVED_OR_REJECT: 'FORCE_APPROVED_OR_REJECT',
    SAVE_REJECTED_EMPLOYEES: 'SAVE_REJECTED_EMPLOYEES',
};
export default APPROVAL
