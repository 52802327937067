export const RADIO = 'RADIO';
export const CHECKBOX = 'CHECKBOX';

export const PI_CHART_SELECTIONS = [
  {
    _id: "BY_CATEGORY",
    name: "Chart by Category",
  },
  {
    _id: "BY_CENTIV_CARD",
    name: "Chart by Centiv Card",
  },
];

export const CARD_TYPE = [
  {
    _id: "CENTIV",
    productId: 1,
    productCode: '36',
    name: "Centiv Card",
  },
  {
    _id: "PAYD",
    productId: 2,
    productCode: '35',
    name: "PayD Card",
  },
];

export const CARD_TYPE_OBJ = {
  CENTIV: 'CENTIV',
  PAYD: 'PAYD',
  PRODUCT_ID_CENTIV: 1,
  PRODUCT_ID_PAYD: 2,
  PRODUCT_CODE_CENTIV: '36',
  PRODUCT_CODE_PAYD: '35',
}

export const PAYD_OTHER = [
  {
    _id: "PAYD",
    name: "PayD Card",
  },
  {
    _id: "OTHER_BANK",
    name: "Other Bank"
  }
];

export const PAYD_OTHER_BOTH = [
  {
    _id: "PAYD",
    name: "PayD Card",
  },
  {
    _id: "OTHER_BANK",
    name: "Other Bank"
  },
  {
    _id: "MULTI_ACCOUNT",
    name: "multiple Account"
  }
];

export const EID_EXPIRY = [
  {
    _id: "NOT_AVAILABLE",
    name: "Not Available",
  },
  {
    _id: "EXPIRED",
    name: "Expired",
  },
  {
    _id: "NEAR_TO_EXPIRE",
    name: "Near to Expire",
  },
];

export const EXCEL_FILETYPES =
  "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
export const PDF_FILETYPE = "application/pdf";
export const CSV_FILETYPES = "text/csv";
export const IMAGE_FILYTYPES = "image/jpeg, image/png";
export const IMAGE_FILETYPE = "image/*";
export const TOPIC = {
  CLIENT_PROFILE_UPDATE: "CLIENT_PROFILE_UPDATE",
  INITIAL_VERIFICATION_APPROVED: "INITIAL_VERIFICATION_APPROVED",
  ON_BOARD_REQUEST: "ON_BOARD_REQUEST",
  ON_BOARD_REQUEST_APPROVED: "ON_BOARD_REQUEST_APPROVED",
  ON_BOARD_REQUEST_REJECT: "ON_BOARD_REQUEST_REJECT",
  EMPLOYEES_FILE_UPLOAD: "EMPLOYEES_FILE_UPLOAD",
  EMPLOYEES_OTHER_BANK_FILE_UPLOAD: "EMPLOYEES_OTHER_BANK_FILE_UPLOAD",
  EMPLOYEE_ONBOARD_REQUEST: "EMPLOYEE_ONBOARD_REQUEST",
  EMPLOYEE_ONBOARD_APPROVED: "EMPLOYEE_ONBOARD_APPROVED",
  EMPLOYEE_ONBOARD_REJECT: "EMPLOYEE_ONBOARD_REJECT",
  EMPLOYEE_UPDATE_REQUEST: "EMPLOYEE_UPDATE_REQUEST",
  SALARY_FILE_UPLOAD: "SALARY_FILE_UPLOAD",
  DEPOSIT_FILE_UPLOAD: "DEPOSIT_FILE_UPLOAD",
  CENTIV_CARD_REQUEST: "CENTIV_CARD_REQUEST",
  CARD_REPLACEMENT_REQUEST: "CARD_REPLACEMENT_REQUEST",
  EMPLOYEE_APPLICATION_BLOCK_REQUEST: "EMPLOYEE_APPLICATION_BLOCK_REQUEST",
  EMPLOYEE_APPLICATION_UN_BLOCK_REQUEST: "EMPLOYEE_APPLICATION_UN_BLOCK_REQUEST",
  USER_BLOCK_REQUEST: "USER_BLOCK_REQUEST",
  CHANGE_EMPLOYEE_TYPE: "CHANGE_EMPLOYEE_TYPE",
  USER_UNBLOCK_REQUEST: "USER_UNBLOCK_REQUEST",
  ESTABLISHMENT_IDS_ADDED: "ESTABLISHMENT_IDS_ADDED",
  UPDATE_MOL_NO: "UPDATE_MOL_NO",
  ESTABLISHMENT_ID_ADDED: "ESTABLISHMENT_ID_ADDED",
  CENTIV_LOAD_FUNDS: "CENTIV_LOAD_FUNDS",
  EMPLOYEE_EID_UPDATE: "EMPLOYEE_EID_UPDATE",
  EMPLOYEE_PHONE_UPDATE: "EMPLOYEE_PHONE_UPDATE",
  CARD_PIN_CHANGE: "CARD_PIN_CHANGE",
  EMPLOYEE_STATEMENT_REQUEST: "EMPLOYEE_STATEMENT_REQUEST",
  CHANGE_EMPLOYEE_TYPE_TO_KAMELPAY: "CHANGE_EMPLOYEE_TYPE_TO_KAMELPAY",
  UPDATE_DATE_OF_BIRTH: 'UPDATE_DATE_OF_BIRTH',
  BULK_INACTIVE_EMPLOYEES: 'BULK_INACTIVE_EMPLOYEES',
  UPDATE_NATIONALITY: 'UPDATE_NATIONALITY',
  UPDATE_EMP_CODE: 'UPDATE_EMP_CODE',
  UPDATE_PASSPORT: 'UPDATE_PASSPORT',
  TEAM_MEMBER_PHONE_UPDATE: 'TEAM_MEMBER_PHONE_UPDATE',
  ADD_UPDATE_CASHBACK: 'ADD_UPDATE_CASHBACK',
  ADD_PRODUCT: 'ADD_PRODUCT',
  ADD_CHARGES: 'ADD_CHARGES',
  EDIT_CHARGES: 'EDIT_CHARGES',
  ADD_BUSINESS_OWNER: 'ADD_BUSINESS_OWNER',
  EDIT_BUSINESS_OWNER: 'EDIT_BUSINESS_OWNER',
  ADD_EDIT_ADDITIONAL_DETAILS: 'ADD_EDIT_ADDITIONAL_DETAILS',
  ADD_OPERATION_USER: 'ADD_OPERATION_USER',
  EDIT_OPERATION_USER: 'EDIT_OPERATION_USER',
  //Exchange house
  EXCHANGE_HOUSE_BRANCH_ONBOARD_REQUEST: 'EXCHANGE_HOUSE_BRANCH_ONBOARD_REQUEST',
  BULK_ESTABLISHMENT_IDS_ADDED: 'BULK_ESTABLISHMENT_IDS_ADDED',
  EOS_FILE_UPLOAD: 'EOS_FILE_UPLOAD',
  INACTIVE_EMPLOYEES: "INACTIVE_EMPLOYEES",
};

export const TOPIC_ARRAY_FOR_APPROVAl = [
  { _id: 'CLIENT_PROFILE_UPDATE', name: 'Client Profile Update' },
  { _id: 'INITIAL_VERIFICATION_APPROVED', name: 'Initial Verification Approved' },
  { _id: 'ON_BOARD_REQUEST_APPROVED', name: 'On Board Request Approved' },
  { _id: 'ON_BOARD_REQUEST_REJECT', name: 'On Board Request Rejected' },
  { _id: 'EMPLOYEES_FILE_UPLOAD', name: 'Employees File Upload' },
  { _id: 'EMPLOYEES_OTHER_BANK_FILE_UPLOAD', name: 'Employees Other Bank File Upload' },
  { _id: 'EMPLOYEE_ONBOARD_REQUEST', name: 'Employee Onboard Request' },
  { _id: 'EMPLOYEE_ONBOARD_APPROVED', name: 'Employee Onboard Approved' },
  { _id: 'EMPLOYEE_ONBOARD_REJECT', name: 'Employee Onboard Rejected' },
  { _id: 'EMPLOYEE_UPDATE_REQUEST', name: 'Employee Update Request' },
  { _id: 'SALARY_FILE_UPLOAD', name: 'Salary File Upload' },
  { _id: 'DEPOSIT_FILE_UPLOAD', name: 'Deposit File Upload' },
  { _id: 'CENTIV_CARD_REQUEST', name: 'Centiv Card Request' },
  { _id: 'CARD_REPLACEMENT_REQUEST', name: 'Card Replacement Request' },
  { _id: 'EMPLOYEE_APPLICATION_BLOCK_REQUEST', name: 'Employee Application Block Request' },
  { _id: 'EMPLOYEE_APPLICATION_UN_BLOCK_REQUEST', name: 'Employee Application Un Block Request' },
  { _id: 'USER_BLOCK_REQUEST', name: 'User Block Request' },
  { _id: 'CHANGE_EMPLOYEE_TYPE', name: 'Add Other Bank Details' },
  { _id: 'CHANGE_EMPLOYEE_TYPE_TO_KAMELPAY', name: 'Add PayD Details' },
  { _id: 'USER_UNBLOCK_REQUEST', name: 'User Unblock Request' },
  { _id: 'ESTABLISHMENT_IDS_ADDED', name: 'Establishment Ids Added' },
  { _id: 'UPDATE_MOL_NO', name: 'Update Mol No' },
  { _id: 'ESTABLISHMENT_ID_ADDED', name: 'Establishment Id Added' },
  { _id: 'CENTIV_LOAD_FUNDS', name: 'Centiv Load Funds' },
  { _id: 'EMPLOYEE_EID_UPDATE', name: 'Employee EID Update' },
  { _id: 'EMPLOYEE_PHONE_UPDATE', name: 'Employee Phone Update' },
  { _id: 'CARD_PIN_CHANGE', name: 'Card PIN Change' },
  { _id: 'EXCHANGE_HOUSE_BRANCH_ONBOARD_REQUEST', name: 'Exchange House Branch Onboard Request' },
  { _id: 'BULK_ESTABLISHMENT_IDS_ADDED', name: 'Bulk Establishment Ids Added' },
  { _id: 'EOS_FILE_UPLOAD', name: 'EOS File Upload' },
  { _id: 'EMPLOYEE_STATEMENT_REQUEST', name: 'Employee Statement Request' },
  { _id: 'UPDATE_DATE_OF_BIRTH', name: 'Update Date Of Birth' },
  { _id: 'BULK_INACTIVE_EMPLOYEES', name: 'Bulk Inactive Employees' },
  { _id: 'UPDATE_NATIONALITY', name: 'Update Nationality' },
  { _id: 'UPDATE_EMP_CODE', name: 'Update Emp Code' },
  { _id: 'UPDATE_PASSPORT', name: 'Update Passport' },
  { _id: 'ADD_UPDATE_CASHBACK', name: 'Add Update Cashback' },
  { _id: 'ADD_PRODUCT', name: 'Add Product' },
  { _id: 'ADD_CHARGES', name: 'Add Charges' },
  { _id: 'EDIT_CHARGES', name: 'Edit Charges' },
  { _id: 'ADD_BUSINESS_OWNER', name: 'Add Business Owner' },
  { _id: 'EDIT_BUSINESS_OWNER', name: 'Edit Business Owner' },
  { _id: 'ADD_EDIT_ADDITIONAL_DETAILS', name: 'Add Edit Additional Details' },
  { _id: 'ADD_OPERATION_USER', name: 'Add Operation User' },
  { _id: 'EDIT_OPERATION_USER', name: 'Edit Operation User' },
  { _id: 'TEAM_MEMBER_PHONE_UPDATE', name: 'Team Member Phone Update' },
  { _id: 'INACTIVE_EMPLOYEES', name: 'Inactive Employees' },
];

export const TOPIC_OBJECT = [
  { _id: 1, name: "INITIAL_UNVERIFIED" },
  { _id: 2, name: "INITIAL_VERIFICATION_APPROVED" },
  { _id: 3, name: "ON_BOARD_REQUEST" },
  { _id: 4, name: "ON_BOARD_REQUEST_APPROVED" },
  { _id: 5, name: "ON_BOARD_REQUEST_REJECT" },
];
export const EMPLOYER_TYPE_OBJECT = {
  EXCHANGE_HOUSE_CLIENT: 'EXCHANGE_HOUSE_CLIENT',
  NON_EXCHANGE_HOUSE_CLIENT: 'NON_EXCHANGE_HOUSE_CLIENT'
}

export const EMPLOYER_TYPE = [
  { _id: "EXCHANGE_HOUSE_CLIENT", name: "EXCHANGE_HOUSE" },
  { _id: "NON_EXCHANGE_HOUSE_CLIENT", name: "KAMELPAY" },

];

export const ACTION_ALL = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  PARTIAL_APPROVED: 'PARTIAL_APPROVED',
  REJECTED_BY_EXCHANGE_HOUSE: 'REJECTED_BY_EXCHANGE_HOUSE',
  REJECTED_BY_EXCHANGE_HOUSE_BRANCH: 'REJECTED_BY_EXCHANGE_HOUSE_BRANCH',
  REJECTED_BY_EXCHANGE_HOUSE_USER: 'REJECTED_BY_EXCHANGE_HOUSE_USER',
  REJECTED_BY_EXCHANGE_HOUSE_BRANCH_USER: 'REJECTED_BY_EXCHANGE_HOUSE_BRANCH_USER',
  REJECTED_BY_CLIENT_ADMIN: 'REJECTED_BY_CLIENT_ADMIN',
  REJECTED_BY_CLIENT: 'REJECTED_BY_CLIENT',
  REJECTED_BY_ADMIN: 'REJECTED_BY_ADMIN',
  APPROVED_BY_ADMIN_AUTHORIZER: 'APPROVED_BY_ADMIN_AUTHORIZER',
  REJECTED_BY_ADMIN_AUTHORIZER: 'REJECTED_BY_ADMIN_AUTHORIZER',
  APPROVED_BY_CLIENT_ADMIN: 'APPROVED_BY_CLIENT_ADMIN',
  APPROVED_BY_EXCHANGE_ADMIN: 'APPROVED_BY_EXCHANGE_ADMIN',
  APPROVED_BY_CLIENT: 'APPROVED_BY_CLIENT',
  APPROVED_BY_AUTHORIZER: 'APPROVED_BY_AUTHORIZER',
  APPROVED_BY_ADMIN: 'APPROVED_BY_ADMIN',
};
export const ACTION = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED"
};

export const ACTION_ARRAY = [
  { _id: ACTION_ALL.PENDING, name: "Pending" },
  { _id: ACTION_ALL.APPROVED, name: "Approved" },
  { _id: ACTION_ALL.REJECTED, name: "Rejected" },
];
export const ACTION_ARRAY_COMMON = [
  { _id: ACTION_ALL.PENDING, name: "Pending" },
  { _id: ACTION_ALL.APPROVED, name: "Approved" },
  { _id: ACTION_ALL.PARTIAL_APPROVED, name: "Partial Approved" },
  { _id: ACTION_ALL.REJECTED, name: "Rejected" },
];
export const ACTION_ARRAY_ALL = [
  { _id: ACTION_ALL.PENDING, name: "Pending" },
  { _id: ACTION_ALL.APPROVED, name: "Approved" },
  { _id: ACTION_ALL.REJECTED, name: "Rejected" },
  { _id: ACTION_ALL.REJECTED_BY_CLIENT, name: "Rejected by Client" },
  { _id: ACTION_ALL.REJECTED_BY_CLIENT_ADMIN, name: "Rejected by Client User" },
  { _id: ACTION_ALL.REJECTED_BY_EXCHANGE_HOUSE, name: "Rejected by Exchange House" },
  { _id: ACTION_ALL.REJECTED_BY_EXCHANGE_HOUSE_BRANCH, name: "Rejected by Exchange House Branch" },
  { _id: ACTION_ALL.REJECTED_BY_EXCHANGE_HOUSE_USER, name: "Rejected by Exchange House User" },
  { _id: ACTION_ALL.REJECTED_BY_EXCHANGE_HOUSE_BRANCH_USER, name: "Rejected by Exchange House Branch User" },
];
export const REJECTED_ARRAY_ALL = [
  { _id: ACTION_ALL.REJECTED_BY_ADMIN, name: "Admin" },
  { _id: ACTION_ALL.REJECTED_BY_CLIENT, name: "Client" },
  { _id: ACTION_ALL.REJECTED_BY_CLIENT_ADMIN, name: "Client User" },
  { _id: ACTION_ALL.REJECTED_BY_EXCHANGE_HOUSE, name: "Exchange House" },
  { _id: ACTION_ALL.REJECTED_BY_EXCHANGE_HOUSE_BRANCH, name: "Exchange House Branch" },
  { _id: ACTION_ALL.REJECTED_BY_EXCHANGE_HOUSE_USER, name: "Exchange House User" },
  { _id: ACTION_ALL.REJECTED_BY_EXCHANGE_HOUSE_BRANCH_USER, name: "Exchange House Branch User" },
];
export const REJECTED_ARRAY_CLIENT = [
  { _id: ACTION_ALL.REJECTED_BY_ADMIN, name: "Admin" },
  { _id: ACTION_ALL.REJECTED_BY_CLIENT, name: "Client" },
  { _id: ACTION_ALL.REJECTED_BY_CLIENT_ADMIN, name: "Client User" },
];
export const PENDING_ON_CLIENT = [
  { _id: ACTION_ALL.APPROVED_BY_AUTHORIZER, name: "Client's Authorizer" },
  { _id: ACTION_ALL.APPROVED_BY_CLIENT_ADMIN, name: "Client" },
  { _id: ACTION_ALL.APPROVED_BY_CLIENT, name: "Checker" },
  { _id: ACTION_ALL.APPROVED_BY_ADMIN_AUTHORIZER, name: "Authorizer" },
];
export const REJECTED_ARRAY_EH = [
  { _id: ACTION_ALL.REJECTED_BY_ADMIN, name: "Admin" },
  { _id: ACTION_ALL.REJECTED_BY_EXCHANGE_HOUSE, name: "Exchange House" },
  { _id: ACTION_ALL.REJECTED_BY_EXCHANGE_HOUSE_BRANCH, name: "Exchange House Branch" },
  { _id: ACTION_ALL.REJECTED_BY_EXCHANGE_HOUSE_USER, name: "Exchange House User" },
  { _id: ACTION_ALL.REJECTED_BY_EXCHANGE_HOUSE_BRANCH_USER, name: "Exchange House Branch User" },
];
export const PENDING_ON_EH = [
  { _id: ACTION_ALL.APPROVED_BY_CLIENT_ADMIN, name: "Exchange House" },
  { _id: ACTION_ALL.APPROVED_BY_CLIENT, name: "Checker" },
  { _id: ACTION_ALL.APPROVED_BY_ADMIN_AUTHORIZER, name: "Authorizer" },
];
export const APPROVAL_TYPE = [
  { _id: "CLIENT", name: "KamelPay Clients", label: "KamelPay Clients", value: "CLIENT" },
  { _id: "EXCHANGE_HOUSE", name: "Exchange House Clients", label: "Exchange House Clients", value: "EXCHANGE_HOUSE" },
];

export const KYC = {
  INITIAL_UNVERIFIED: "INITIAL_UNVERIFIED",
  INITIAL_VERIFICATION_APPROVED: "INITIAL_VERIFICATION_APPROVED",
  ON_BOARD_REQUEST: "ON_BOARD_REQUEST",
  ON_BOARD_REQUEST_APPROVED: "ON_BOARD_REQUEST_APPROVED",
  ON_BOARD_REQUEST_REJECT: "ON_BOARD_REQUEST_REJECT",
  BANK_CHANGE_TYPE_REQUEST: 'BANK_CHANGE_TYPE_REQUEST',
};
export const SALARY_STATUS_ARRAY = (type = '') => {
  if (type === SALARY_TYPES_OBJ.WPS) {
    return {
      title: 'Get PAF',
      headerText: 'PAF Id',
      steps: [
        { _id: "PAF_RECEIVED", name: "PAF Received", title: "PAF Received" },
        { _id: "PAF_FILE_UPLOADED", name: "PAF Processed", title: "PAF Processed" },
        { _id: "TOPUP_REQUEST_GENERATED", name: "Card Load Request Sent", title: "Card Load Request Sent" },
        { _id: "SALARY_CREDITED", name: "Salary Credited", title: "Salary Credited" },
      ]
    }
  } else if (type === SALARY_TYPES_OBJ.NON_WPS) {
    return {
      title: 'Get Salary File',
      headerText: 'Salary Id',
      steps: [
        { _id: "TOPUP_REQUEST_GENERATED", name: "Card Load Request Sent", title: "Card Load Request Sent" },
        { _id: "SALARY_CREDITED", name: "Salary Credited", title: "Salary Credited" }
      ]
    }
  } else if (type === SALARY_TYPES_OBJ.OTHER_BANK) {
    return {
      title: 'Get SIF',
      headerText: 'SIF Id',
      steps: [
        { _id: "SIF_RECEIVED", name: "SIF Sent", title: "SIF Sent" },
        { _id: "SIF_FILE_UPLOADED", name: "SIF Ack Received", title: "SIF Ack Received" }
      ]
    }
  }
  return {};
};

export const ACTIVE_INACTIVE = [
  {
    _id: "ACTIVE",
    name: "Active",
  },
  {
    _id: "INACTIVE",
    name: "In-Active",
  },
];

export const YES_NO = [
  {
    _id: "true",
    name: "Yes",
  },
  {
    _id: "false",
    name: "No",
  },
];

export const YESNO = [
  {
    _id: "YES",
    name: "Yes",
  },
  {
    _id: "NO",
    name: "No",
  },
];

export const LOGS_TYPES = [
  // {
  //   _id: "DOT_NET",
  //   name: "Dot Net",
  // },
  {
    _id: "SUREPASS",
    name: "Surepass",
  },
  {
    _id: "HRCMS",
    name: "hrcms",
  },
  {
    _id: "BENCH_MATRIX",
    name: "Bench-Matrix",
  },
];

export const CAT_SUB_CAT = [
  { label: "Category", value: "CATEGORY" },
  { label: "Sub Category", value: "SUB_CATEGORY" },
];

export const WITH_OR_WITHOUT_VARIATION = [
  { label: "With Variants", value: "WITH_VARIANTS" },
  { label: "Without Variants", value: "WITHOUT_VARIANTS" },
];

export const CLIENT_APPROVAL_STATUS = {
  APPROVED_BY_CLIENT_ADMIN: "APPROVED_BY_CLIENT_ADMIN",
  APPROVED_BY_CLIENT: "APPROVED_BY_CLIENT",
  APPROVED_BY_AUTHORIZER: 'APPROVED_BY_AUTHORIZER',
  REJECTED_BY_CLIENT: "REJECTED_BY_CLIENT",
  APPROVED_BY_ADMIN: "APPROVED_BY_ADMIN",
  APPROVED_BY_ADMIN_AUTHORIZER: "APPROVED_BY_ADMIN_AUTHORIZER",
  REJECTED_BY_ADMIN_AUTHORIZER: "REJECTED_BY_ADMIN_AUTHORIZER",
  REJECTED_BY_ADMIN: "REJECTED_BY_ADMIN",
  REJECTED_BY_AUTHORIZER: 'REJECTED_BY_AUTHORIZER',
};

export const EMPLOYEE_DOCUMENTS = [
  // { _id: 4, name: "Sponsor Document" },
  { _id: 5, name: "Labour Card" },
  { _id: 6, name: "Emirates Id" },
  { _id: 7, name: "Residence Visa" },
  { _id: 8, name: "Passport" },
];

export const REPLACEMENT_CARD_TRACKING_STATUS = [
  { _id: 'FILE_SEND_TO_PERSOU', name: "File Send To Persou" },
  { _id: 'CARD_PRINTED', name: "Card Printed" },
  { _id: 'CARD_RECEIVED_FROM_PERSOU', name: "Card Received From Persou" },
  { _id: 'CARD_RECEIVED_IN_H_O', name: "Card Received in H O" },
  { _id: 'ASSIGNED_TO_EXECUTIVE', name: "Assigned to Executive" },
  { _id: 'PERSON_NOT_AVAILABLE', name: "Person Not Available" },
  { _id: 'REQUEST_FOR_LOCATION_CHANGE', name: "Request for Location Change" },
  { _id: 'CARD_ACTIVATION_REQUEST_SEND', name: "Card Activation Request Send" },
  { _id: 'CARD_DELIVERED', name: "Card Delivered" },
  { _id: 'CARD_ACTIVATED', name: "Card Activated" },
  { _id: 'CARD_REPLACEMENT_REQUESTED', name: "Card Replacement Requested" },
  { _id: 'CARD_REPLACEMENT_FAILED', name: "Card Replacement Failed" },
  { _id: 'CARD_REPLACEMENT_SUCCESS', name: "Card Replacement Success" },
  { _id: 'HOT_BLOCK', name: "Hot Block" },
  { _id: 'WARM_BLOCK', name: "Warm Block" },
  { _id: 'ACTIVE', name: "Active" },
  { _id: 'LOSS_STOLEN', name: "Loss/Stolen" },
  { _id: 'CARD_CREATION_FROM_PERSOU', name: "Card Creation From Persou" },
  { _id: 'HOT_MARK_REQUESTED', name: "Hot Mark Requested" },
  { _id: 'HOT_MARK_FAILED', name: "Hot Mark Failed" },
  // { _id: 'CARD_COLLECTED', name: "Card Collecte" }
]

// export const PayrollTypes = [
//   { _id: "WPS", name: "WPS" },
//   { _id: "NON_WPS", name: "NON_WPS" },
// ];
export const EMPLOYER_DOCUMENTS = [
  { _id: 1, name: "Trade License" },
  { _id: 2, name: "Establishment Card" },
  { _id: 3, name: "Labour Card" },
  { _id: 4, name: "Sponsor Document" },
  { _id: 9, name: "Mansha List" },
];

export const PAYROLL_TYPES = [
  { _id: "WPS", name: "WPS" },
  { _id: "NON_WPS", name: "NON WPS" },
];

export const SALARY_TYPES = [
  { _id: "WPS", name: "WPS" },
  { _id: "NON-WPS", name: "Non WPS" },
  { _id: "OtherBank", name: "Other Bank" },
];

export const SALARY_TYPES_OBJ = {
  WPS: 'WPS',
  NON_WPS: 'NON-WPS',
  OTHER_BANK: 'OtherBank'
};

export const IMPORTANT_DOCS = [
  { _id: 1, name: "Trade License" },
  { _id: 4, name: "Sponsor Document" },
];

export const APPROVAL_MESSAGES = {
  APPROVED_BY_CLIENT_ADMIN: "Waiting for Client's Approval",
  APPROVED_BY_EXCHANGE_ADMIN: "Waiting for Exchange House Approval",
  APPROVED_BY_CLIENT: "Waiting for Checker's approval",
  APPROVED_BY_AUTHORIZER: "Waiting for Client's Authorizer Approval",
  APPROVED_BY_ADMIN: "Approved",
  APPROVED_BY_ADMIN_AUTHORIZER: "Waiting for Authorizer's Approval",
  REJECTED_BY_ADMIN_AUTHORIZER: "Rejected by Authorizer",
  REJECTED_BY_ADMIN: "Rejected",
  REJECTED_BY_CLIENT: "Rejected by Client",
  REJECTED_BY_CLIENT_ADMIN: 'Rejected by Client User',
  REJECTED_BY_EXCHANGE_HOUSE: 'Rejected by Exchange House',
  REJECTED_BY_EXCHANGE_HOUSE_BRANCH: 'Rejected by Exchange House Branch',
  REJECTED_BY_EXCHANGE_HOUSE_USER: 'Rejected by Exchange House User',
  REJECTED_BY_EXCHANGE_HOUSE_BRANCH_USER: 'Rejected by Exchange House Branch User',
};

export const CLIENT_TYPE = {
  NON_EXCHANGE_HOUSE_CLIENT: "Kamelpay",
  EXCHANGE_HOUSE_CLIENT: "Exchange House",
};

export const CLIENT_TYPE_VALUE = {
  NON_EXCHANGE_HOUSE_CLIENT: "NON_EXCHANGE_HOUSE_CLIENT",
  EXCHANGE_HOUSE_CLIENT: "EXCHANGE_HOUSE_CLIENT",
};

export const APPROVAL_MESSAGES_ARRAY = [
  { _id: "APPROVED_BY_CLIENT_ADMIN", name: "Waiting for Client's Approval" },//0
  { _id: "APPROVED_BY_AUTHORIZER", name: "Waiting For Client's Authorizer Approval" },//1
  { _id: "APPROVED_BY_CLIENT", name: "Waiting for checker's approval" },//2
  { _id: "APPROVED_BY_ADMIN", name: "Approved" },//3
  { _id: "REJECTED_BY_ADMIN", name: "Rejected" },//4
  { _id: "REJECTED_BY_CLIENT", name: "Rejected by Client" },//5
  { _id: 'REJECTED_BY_CLIENT_ADMIN', name: 'Rejected by Client User' },//6
  { _id: "REJECTED_BY_AUTHORIZER", name: "Rejected by Authorizer" },//7
  { _id: "APPROVED_BY_ADMIN_AUTHORIZER", name: "Waiting for Authorizer's Approval" },//8
  { _id: "REJECTED_BY_ADMIN_AUTHORIZER", name: "Rejected by Authorizer" },//9
];

export const APPROVAL_MESSAGES_EXCHANGE_ARRAY = [
  { _id: "APPROVED_BY_CLIENT_ADMIN", name: "Waiting for Exchange's Approval" },
  { _id: "APPROVED_BY_CLIENT", name: "Waiting for checker's approval" },
  { _id: "APPROVED_BY_ADMIN", name: "Approved" },
  { _id: "REJECTED_BY_ADMIN", name: "Rejected" },
  { _id: 'REJECTED_BY_EXCHANGE_HOUSE', name: 'Rejected by Exchange House' },
  { _id: 'REJECTED_BY_EXCHANGE_HOUSE_BRANCH', name: 'Rejected by Exchange House Branch' },
  { _id: 'REJECTED_BY_EXCHANGE_HOUSE_USER', name: 'Rejected by Exchange House User' },
  { _id: 'REJECTED_BY_EXCHANGE_HOUSE_BRANCH_USER', name: 'Rejected by Exchange House Branch User' },
  { _id: "APPROVED_BY_ADMIN_AUTHORIZER", name: "Waiting for Exchange House Authorizer's Approval" },//8
  { _id: "REJECTED_BY_ADMIN_AUTHORIZER", name: "Rejected by Exchange House Authorizer" },//9
];
export const APPROVAL_MESSAGES_ONBOARD_ARRAY = [
  { _id: "APPROVED_BY_CLIENT_ADMIN", name: "Waiting for Client's /Exchange's Approval" },
  { _id: "APPROVED_BY_CLIENT", name: "Waiting for checker's approval" },
  { _id: "APPROVED_BY_ADMIN", name: "Approved" },
  { _id: "REJECTED_BY_ADMIN", name: "Rejected" },
  { _id: "REJECTED_BY_CLIENT", name: "Rejected by Client" },
  { _id: 'REJECTED_BY_CLIENT_ADMIN', name: 'Rejected by Client User' },
  { _id: 'REJECTED_BY_EXCHANGE_HOUSE', name: 'Rejected by Exchange House' },
  { _id: 'REJECTED_BY_EXCHANGE_HOUSE_USER', name: 'Rejected by Exchange House User' },
  { _id: 'REJECTED_BY_EXCHANGE_HOUSE_BRANCH', name: 'Rejected by Exchange House Branch' },
  { _id: 'REJECTED_BY_EXCHANGE_HOUSE_BRANCH_USER', name: 'Rejected by Exchange House Branch User' },
];
export const CARD_STATUS = {
  PENDING: "Pending",
  COMPLIANCE_BANK_PASSED: "Compliance Bank Passed",
  COMPLIANCE_BANK_FAIL: "Compliance Bank Fail",
  CARD_ON_PRINTING: "Card On Printing",
  CARD_ON_PRINTED: "Card On Printed",
  DELIVERED: "Delivered",
};
export const CARD_STATUS_ARRAY = [
  { _id: "PENDING", title: "Pending" },
  { _id: "COMPLIANCE_BANK_PASSED", title: "Compliance Bank Passed" },
  { _id: "COMPLIANCE_BANK_FAIL", title: "Compliance Bank Fail" },
  { _id: "CARD_ON_PRINTING", title: "Card On Printing" },
  { _id: "CARD_ON_PRINTED", title: "Card On Printed" },
  { _id: "DELIVERED", title: "Delivered" },
];
export const EMPLOYEE_TYPE = [
  { _id: "KAMELPAY", name: "Kamel Pay" },
  { _id: "OTHER_BANK", name: "Other Bank" },
];

export const EMPLOYEE_TYPE_OBJ = {
  KAMELPAY: "KAMELPAY",
  OTHER_BANK: "OTHER_BANK"
};

export const STATUS = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};

export const STATUS_ARRAY = [
  { _id: "PENDING", name: "Pending" },
  { _id: "APPROVED", name: "Approved" },
  { _id: "REJECTED", name: "Rejected" },
];

export const ACL_OPTIONS = {
  DASHBOARD: ["DASHBOARD_VIEW"],
  APPROVAL: ["APPROVAL_VIEW", "APPROVAL_APPROVE/REJECT"],
  EMPLOYER: ["EMPLOYER_VIEW"],
  EXCHANGE_HOUSE: ["EXCHANGE_EXCHANGE_HOUSE_VIEW"],
  ADVANCE_SALARY: ["ADVANCE_SALARY_VIEW"],
  TRANSACTIONS: ["TRANSACTIONS_VIEW"],
  CENTIV_CARD: ["CENTIV_CARD_VIEW"],
  PROCESS_SALARIES: ["PROCESSSALARIES_CAN_PROCESS"],
  FIND_CARD_HOLDER: ["FIND_CARD_HOLDER_VIEW"],
  REMITTANCES: ["REMITTANCES_VIEW"],
  MARKETPLACE: [
    "MARKETPLACE_PRODUCTS_VIEW",//0
    "MARKETPLACE_PRODUCTS_ADD",//1
    "MARKETPLACE_PRODUCTS_IMAGES_UPLOAD",//2
    "MARKETPLACE_CATEGORIES/SUBCATEGORY_VIEW",//3
    "MARKETPLACE_CATEGORIES/SUBCATEGORY_ADD",//4
    "MARKETPLACE_SUPPLIERS_VIEW",//5
    "MARKETPLACE_SUPPLIERS_ADD",//6
    "MARKETPLACE_PRODUCT_INVENTORY_VIEW", //7
    "MARKETPLACE_PRODUCT_INVENTORY_UPDATE", //8
    "MARKETPLACE_ORDERS_VIEW",//9,
    "MARKETPLACE_ORDERS_ACTION",//10,
  ],
  // MANAGE_USERS: ["USERS_CARD_ACTIVE", 'USERS_PIN_CHANGE'],
};

export const DOCUMENTS = [
  { _id: 1, name: "Trade License", type: 'C' },
  { _id: 2, name: "Establishment Card", type: 'C' },
  { _id: 3, name: "Labour Card", type: 'C' },
  { _id: 4, name: "Sponsor Document", type: 'C' },
  { _id: 5, name: "Labour Card", type: 'E' },
  { _id: 6, name: "Emirates Id", type: 'E' },
  { _id: 7, name: "Residence Visa", type: 'E' },
  { _id: 8, name: "Passport", type: 'E' },
  { _id: 9, name: "Mansha List", type: 'C' },
  { _id: 10, name: "Sponsor Passport", type: 'C' },
  { _id: 11, name: "Sponsor Emirates Id", type: 'C' },
  { _id: 12, name: "KYCPeper", type: 'E' },
];

export const DOCUMENTS_OBJ = {
  1: { name: "Trade License", description: "Trade License", type: 'C' },
  2: { name: "Establishment Card", description: "Establishment Card", type: 'C' },
  3: { name: "Labour Card", description: "Labour Card", type: 'C' },
  4: { name: "Sponsor Document", description: "Sponsor Document", type: 'C' },
  5: { name: "Labour Card", description: "Labour Card", type: 'E' },
  6: { name: "Emirates Id", description: "Emirates Id", type: 'E' },
  7: { name: "Residence Visa", description: "Residence Visa", type: 'E' },
  8: { name: "Passport", description: "Passport", type: 'E' },
  9: { name: "Mansha List", description: "Mansha List", type: 'C' },
  10: { name: "Sponsor Passport", description: "Sponsor Passport", type: 'C' },
  11: { name: "Sponsor Emirates Id", description: "Sponsor Emirates Id", type: 'C' },
  12: { name: "KYCPeper", description: "KYC Paper", type: 'E' },
};

export const ROLES = [
  {
    _id: "SUB_ADMIN",
    name: "Sub Admin",
  },
  {
    _id: "CSR",
    name: "Customer Services",
  },
];

export const INITIALS = [
  {
    _id: "MR",
    name: "MR",
  },
  {
    _id: "MS",
    name: "MS",
  },
];

export const GENDER = [
  {
    _id: "M",
    name: "Male",
  },
  {
    _id: "F",
    name: "Female",
  },
];

export const CARD_API_IDS = {
  BLOCK_APPLICATION: "BLOCK_APPLICATION",
  ACTIVATE_APPLICATION: "ACTIVATE_APPLICATION",
  BLOCK_CARD: "BLOCK_CARD",
  ACTIVATE_CARD: "ACTIVATE_CARD",
  REPLACE_CARD: "REPLACE_CARD",
  USER_BLOCK_REQUEST: "USER_BLOCK_REQUEST",
};

export const WHITELIST_BLACKLISTCAPS = {
  WHITE_LIST: 'WHITELIST',
  BLACK_LIST: 'BLACKLIST'
}

export const WHITELIST_BLACKLIST = {
  WHITE_LIST: 'WhiteList',
  BLACK_LIST: 'BlackList'
}

export const REPORTS = [
  // {
  //   _id: "REMITTANCE",
  //   name: "Remittance Report",
  //   fileName: "remittance_report",
  //   url: "https://static-medias.s3.ap-southeast-1.amazonaws.com/remittance+report.xlsx",
  // },
  // {
  //   _id: "CASHOUT",
  //   name: "Cashout Report",
  //   fileName: "cashout_report",
  //   url: "https://static-medias.s3.ap-southeast-1.amazonaws.com/cash-withdrawal-report.xlsx",
  // },
  // {
  //   _id: "BILLPAY",
  //   name: "BillPay Report",
  //   fileName: "billPay_report",
  //   url: "https://static-medias.s3.ap-southeast-1.amazonaws.com/billpay.xlsx",
  // },
  // {
  //   _id: "ADVANCE_SALARY",
  //   name: "Advance Salary Report",
  //   fileName: "advance_salary_report",
  //   url: "https://firebasestorage.googleapis.com/v0/b/kamelpay-2a233.appspot.com/o/advance%20salary.xlsx?alt=media&token=f29c52de-ca67-4fa4-a08d-27fcc7869340",
  // },
  {
    _id: "SALARY_PROCESSED",
    name: "Salary Processed Report",
    fileName: "salary_processed_report",
    url: "https://static-medias.s3.ap-southeast-1.amazonaws.com/salaries-processed.xlsx",
  },
  {
    _id: "SALARY_NOT_PROCESSED",
    name: "Salary Not Processed",
    fileName: "salary_not_processed",
    url: "https://static-medias.s3.ap-southeast-1.amazonaws.com/salaries-processed-cards.xlsx",
  },
];

export const approvalStatusArrayClient = (topic, isInThreeStep) =>
  topic === TOPIC.ON_BOARD_REQUEST ||
    topic === TOPIC.EXCHANGE_HOUSE_BRANCH_ONBOARD_REQUEST
    ? [
      { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
      { _id: "APPROVED_BY_CLIENT", title: "Documents Checked" },
      ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
      { _id: "", title: "Approved By Admin" },
      { _id: "", title: "Compliance Passed" },
      { _id: "APPROVED_BY_ADMIN", title: "Onboarded" },
    ]
    : topic === TOPIC.ESTABLISHMENT_IDS_ADDED || topic === TOPIC.ESTABLISHMENT_ID_ADDED || topic === TOPIC.BULK_ESTABLISHMENT_IDS_ADDED || topic === TOPIC.BULK_INACTIVE_EMPLOYEES
      ? [
        { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
        { _id: "APPROVED_BY_CLIENT", title: "Approved By Client" },
        ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
        { _id: "", title: "Approved By Admin" },
        { _id: "APPROVED_BY_ADMIN", title: "Processed" },
      ]
      : topic === TOPIC.INACTIVE_EMPLOYEES
        ? [
          { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
          { _id: "APPROVED_BY_CLIENT", title: "Approved By Client" },
          ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
          { _id: "", title: "Approved By Admin" },
          { _id: "APPROVED_BY_ADMIN", title: "Processed" },
        ]
        : topic === TOPIC.UPDATE_MOL_NO || TOPIC.UPDATE_NATIONALITY || TOPIC.UPDATE_EMP_CODE || TOPIC.UPDATE_PASSPORT || TOPIC.ADD_UPDATE_CASHBACK || TOPIC.ADD_PRODUCT || TOPIC.ADD_CHARGES || TOPIC.EDIT_CHARGES || TOPIC.ADD_BUSINESS_OWNER || TOPIC.EDIT_BUSINESS_OWNER || TOPIC.ADD_EDIT_ADDITIONAL_DETAILS || TOPIC.ADD_OPERATION_USER || TOPIC.EDIT_OPERATION_USER
          ? [
            { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
            { _id: "APPROVED_BY_CLIENT", title: "Approved By Client" },
            ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
            { _id: "", title: "Approved By Admin" },
            { _id: "APPROVED_BY_ADMIN", title: "Processed" },
          ]
          : topic === TOPIC.EMPLOYEE_PHONE_UPDATE ||
            topic === TOPIC.EMPLOYEE_EID_UPDATE ||
            topic === TOPIC.UPDATE_DATE_OF_BIRTH ||
            topic === TOPIC.CARD_PIN_CHANGE ||
            topic === TOPIC.EMPLOYEE_UPDATE_REQUEST ||
            topic === TOPIC.TEAM_MEMBER_PHONE_UPDATE
            ? [
              { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
              { _id: "APPROVED_BY_CLIENT", title: "Approved By Client" },
              ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
              { _id: "", title: "Approved By Admin" },
              { _id: "APPROVED_BY_ADMIN", title: "Processed" },
            ]
            : topic === TOPIC.CHANGE_EMPLOYEE_TYPE ||
              topic === TOPIC.EMPLOYEE_APPLICATION_BLOCK_REQUEST ||
              topic === TOPIC.EMPLOYEE_APPLICATION_UN_BLOCK_REQUEST ||
              topic === TOPIC?.CENTIV_LOAD_FUNDS ||
              topic === TOPIC?.CHANGE_EMPLOYEE_TYPE_TO_KAMELPAY
              ? [
                { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
                { _id: "APPROVED_BY_CLIENT", title: "Approved By Client" },
                ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
                { _id: "", title: "Approved By Admin" },
                { _id: "APPROVED_BY_ADMIN", title: "Processed" },
              ]
              : topic === TOPIC.EMPLOYEE_ONBOARD_REQUEST
                ? [
                  { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
                  { _id: "APPROVED_BY_CLIENT", title: "Approved By Client" },
                  ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
                  { _id: "", title: "Approved By Admin" },
                  { _id: "", title: "Documents Checked" },
                  { _id: "", title: "Compliance Passed" },
                  { _id: "", title: "Card Printed" },
                  { _id: "", title: "Courier on the way" },
                  { _id: "APPROVED_BY_ADMIN", title: "Card Delivered" },
                ]
                : topic === TOPIC.EMPLOYEES_FILE_UPLOAD
                  ? [
                    { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
                    { _id: "APPROVED_BY_CLIENT", title: "Approved By Client" },
                    ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
                    { _id: "APPROVED_BY_ADMIN", title: "Approved By Admin" },
                    { _id: "", title: "Compliance Passed" },
                    { _id: "", title: "Card Printed" },
                    { _id: "", title: "Courier on the way" },
                    { _id: "APPROVED_BY_ADMIN", title: "Delivered" },
                  ]
                  : topic === TOPIC.CARD_REPLACEMENT_REQUEST
                    ? [
                      { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
                      { _id: "APPROVED_BY_CLIENT", title: "Approved By Client" },
                      ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
                      { _id: "", title: "Approved By Admin" },
                      { _id: "", title: "Card Permanently Blocked" },
                      { _id: "", title: "Replacement Request Sent" },
                      { _id: "", title: "Card Printed" },
                      { _id: "", title: "Courier on the way" },
                      { _id: "APPROVED_BY_ADMIN", title: "Delivered" },
                    ]
                    : topic === TOPIC.DEPOSIT_FILE_UPLOAD
                      ? [
                        { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
                        { _id: "APPROVED_BY_CLIENT", title: "Approved By Client" },
                        ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
                        { _id: "", title: "Approved By Admin" },
                        { _id: "APPROVED_BY_ADMIN", title: "Company Account Credited" },
                      ]
                      : topic === TOPIC.SALARY_FILE_UPLOAD
                        ? [
                          { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
                          { _id: "APPROVED_BY_CLIENT", title: "Approved By Client" },
                          ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
                          { _id: "", title: "Approved By Admin" },
                          { _id: "APPROVED_BY_ADMIN", title: "Salary Processed" },
                        ]
                        : topic === TOPIC.CLIENT_PROFILE_UPDATE || topic === TOPIC.EMPLOYEE_STATEMENT_REQUEST
                          ? [
                            { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
                            { _id: "APPROVED_BY_CLIENT", title: "Approved By Client" },
                            ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
                            { _id: "APPROVED_BY_ADMIN", title: "Approved By Admin" },
                          ]
                          : topic === TOPIC.EOS_FILE_UPLOAD
                            ? [
                              { _id: "APPROVED_BY_CLIENT", title: "Pending" },
                              ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
                              { _id: "APPROVED_BY_ADMIN", title: "Approved By Admin" },
                            ]
                            : [];

export const approvalStatusArrayExchange = (topic, isInThreeStep) =>
  topic === TOPIC.ON_BOARD_REQUEST ||
    topic === TOPIC.EXCHANGE_HOUSE_BRANCH_ONBOARD_REQUEST
    ? [
      { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
      { _id: "APPROVED_BY_CLIENT", title: "Approved By Exchange House" },
      ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
      { _id: "", title: "Approved By Admin" },
      { _id: "", title: "Compliance Passed" },
      { _id: "APPROVED_BY_ADMIN", title: "Onboarded" },
    ]
    : topic === TOPIC.ESTABLISHMENT_IDS_ADDED || topic === TOPIC.ESTABLISHMENT_ID_ADDED || topic === TOPIC.BULK_ESTABLISHMENT_IDS_ADDED
      ? [
        { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
        { _id: "APPROVED_BY_CLIENT", title: "Approved By Exchange House" },
        ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
        { _id: "", title: "Approved By Admin" },
        { _id: "APPROVED_BY_ADMIN", title: "Processed" },
      ]
      : topic === TOPIC.UPDATE_MOL_NO || TOPIC.UPDATE_NATIONALITY || TOPIC.UPDATE_EMP_CODE || TOPIC.UPDATE_PASSPORT || TOPIC.ADD_UPDATE_CASHBACK || TOPIC.ADD_PRODUCT || TOPIC.ADD_CHARGES || TOPIC.EDIT_CHARGES || TOPIC.ADD_BUSINESS_OWNER || TOPIC.EDIT_BUSINESS_OWNER || TOPIC.ADD_EDIT_ADDITIONAL_DETAILS || TOPIC.ADD_OPERATION_USER || TOPIC.EDIT_OPERATION_USER
        ? [
          { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
          { _id: "APPROVED_BY_CLIENT", title: "Approved By Exchange House" },
          ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
          { _id: "", title: "Approved By Admin" },
          { _id: "APPROVED_BY_ADMIN", title: "Processed" },
        ]
        : topic === TOPIC.EMPLOYEE_PHONE_UPDATE ||
          topic === TOPIC.EMPLOYEE_EID_UPDATE ||
          topic === TOPIC.UPDATE_DATE_OF_BIRTH ||
          topic === TOPIC.CARD_PIN_CHANGE
          ? [
            { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
            { _id: "APPROVED_BY_CLIENT", title: "Approved By Exchange House" },
            ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
            { _id: "", title: "Approved By Admin" },
            { _id: "APPROVED_BY_ADMIN", title: "Processed" },
          ]
          : topic === TOPIC.CHANGE_EMPLOYEE_TYPE ||
            topic === TOPIC.EMPLOYEE_APPLICATION_BLOCK_REQUEST ||
            topic === TOPIC.EMPLOYEE_APPLICATION_UN_BLOCK_REQUEST ||
            topic === TOPIC?.CENTIV_LOAD_FUNDS
            ? [
              { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
              { _id: "APPROVED_BY_CLIENT", title: "Approved By Exchange House" },
              ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
              { _id: "", title: "Approved By Admin" },
              { _id: "APPROVED_BY_ADMIN", title: "Processed" },
            ]
            : topic === TOPIC.EMPLOYEE_ONBOARD_REQUEST
              ? [
                { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
                { _id: "APPROVED_BY_CLIENT", title: "Approved By Exchange House" },
                ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
                { _id: "", title: "Approved By Admin" },
                { _id: "", title: "Documents Checked" },
                { _id: "", title: "Compliance Passed" },
                { _id: "", title: "Card Printed" },
                { _id: "", title: "Courier on the way" },
                { _id: "APPROVED_BY_ADMIN", title: "Card Delivered" },
              ]
              : topic === TOPIC.EMPLOYEES_FILE_UPLOAD
                ? [
                  { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
                  { _id: "APPROVED_BY_CLIENT", title: "Approved By Exchange House" },
                  ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
                  { _id: "APPROVED_BY_ADMIN", title: "Approved By Admin" },
                  { _id: "", title: "Compliance Passed" },
                  { _id: "", title: "Card Printed" },
                  { _id: "", title: "Courier on the way" },
                  { _id: "APPROVED_BY_ADMIN", title: "Delivered" },
                ]
                : topic === TOPIC.CARD_REPLACEMENT_REQUEST
                  ? [
                    { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
                    { _id: "APPROVED_BY_CLIENT", title: "Approved By Exchange House" },
                    ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
                    { _id: "", title: "Approved By Admin" },
                    { _id: "", title: "Card Permanently Blocked" },
                    { _id: "", title: "Replacement Request Sent" },
                    { _id: "", title: "Card Printed" },
                    { _id: "", title: "Courier on the way" },
                    { _id: "APPROVED_BY_ADMIN", title: "Delivered" },
                  ]
                  : topic === TOPIC.DEPOSIT_FILE_UPLOAD
                    ? [
                      { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
                      { _id: "APPROVED_BY_CLIENT", title: "Approved By Exchange House" },
                      ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
                      { _id: "", title: "Approved By Admin" },
                      { _id: "APPROVED_BY_ADMIN", title: "Company Account Credited" },
                    ]
                    : topic === TOPIC.SALARY_FILE_UPLOAD
                      ? [
                        { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
                        { _id: "APPROVED_BY_CLIENT", title: "Approved By Exchange House" },
                        ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
                        { _id: "", title: "Approved By Admin" },
                        { _id: "APPROVED_BY_ADMIN", title: "Salary Processed" },
                      ]
                      : topic === TOPIC.CLIENT_PROFILE_UPDATE || topic === TOPIC.EMPLOYEE_STATEMENT_REQUEST
                        ? [
                          { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
                          { _id: "APPROVED_BY_CLIENT", title: "Approved By Exchange House" },
                          ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
                          { _id: "APPROVED_BY_ADMIN", title: "Approved By Admin" },
                        ]
                        : topic === TOPIC.EOS_FILE_UPLOAD
                          ? [
                            { _id: "APPROVED_BY_CLIENT", title: "Pending" },
                            ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
                            { _id: "APPROVED_BY_ADMIN", title: "Approved By Admin" },
                          ]
                          : [];

export const NATIONALITY = [
  { _id: "AF", name: "Afghanistan" },
  { _id: "AX", name: "Aland Islands" },
  { _id: "AL", name: "Albania" },
  { _id: "DZ", name: "Algeria" },
  { _id: "AS", name: "American Samoa" },
  { _id: "AD", name: "Andorra" },
  { _id: "AO", name: "Angola" },
  { _id: "AI", name: "Anguilla" },
  { _id: "AQ", name: "Antarctica" },
  { _id: "AG", name: "Antigua And Barbuda" },
  { _id: "AR", name: "Argentina" },
  { _id: "AM", name: "Armenia" },
  { _id: "AW", name: "Aruba" },
  { _id: "AU", name: "Australia" },
  { _id: "AT", name: "Austria" },
  { _id: "AZ", name: "Azerbaijan" },
  { _id: "BS", name: "Bahamas" },
  { _id: "BH", name: "Bahrain" },
  { _id: "BD", name: "Bangladesh" },
  { _id: "BB", name: "Barbados" },
  { _id: "BY", name: "Belarus" },
  { _id: "BE", name: "Belgium" },
  { _id: "BZ", name: "Belize" },
  { _id: "BJ", name: "Benin" },
  { _id: "BM", name: "Bermuda" },
  { _id: "BT", name: "Bhutan" },
  { _id: "BO", name: "Bolivia" },
  { _id: "BQ", name: "Caribbean Netherland" },
  { _id: "BA", name: "Bosnia - Herzegovina" },
  { _id: "BW", name: "Botswana" },
  { _id: "BV", name: "Bouvet Island" },
  { _id: "BR", name: "Brazil" },
  { _id: "IO", name: "British Indian Ocean" },
  { _id: "VG", name: "US Virgin Islands" },
  { _id: "BN", name: "Brunei Darussalam " },
  { _id: "BG", name: "Bulgaria" },
  { _id: "BF", name: "Burkina Faso" },
  { _id: "BI", name: "Burundi" },
  { _id: "KH", name: "Cambodia" },
  { _id: "CM", name: "Cameroon" },
  { _id: "CA", name: "Canada" },
  { _id: "CV", name: "Cape Verde" },
  { _id: "KH", name: "Cayman Islands" },
  { _id: "CF", name: "Central African" },
  { _id: "TD", name: "Chad" },
  { _id: "CL", name: "Chile" },
  { _id: "CX", name: "Christmas Islands" },
  { _id: "CC", name: "Cocos Islands" },
  { _id: "CO", name: "Columbia" },
  { _id: "KM", name: "Comoro Islands" },
  { _id: "CG", name: "Congo" },
  { _id: "CD", name: "Congo - Kinshasa" },
  { _id: "CK", name: "Cook Islands" },
  { _id: "CR", name: "Costa Rica" },
  { _id: "CI", name: "Cote D'Ivore" },
  { _id: "XP", name: "Country XPD & XPT" },
  { _id: "HR", name: "Croatia" },
  { _id: "CU", name: "Cuba" },
  { _id: "CW", name: "Curacao" },
  { _id: "CY", name: "Cyprus" },
  { _id: "CZ", name: "Czech Republic" },
  { _id: "DK", name: "Denmark" },
  { _id: "DJ", name: "Djibouti" },
  { _id: "DM", name: "Dominica" },
  { _id: "DO", name: "Dominican Republic" },
  { _id: "TP", name: "East Timor" },
  { _id: "EC", name: "Ecuador" },
  { _id: "EG", name: "Egypt" },
  { _id: "SV", name: "El Salvador" },
  { _id: "GQ", name: "Equatorial Guinea" },
  { _id: "ER", name: "Eritrea" },
  { _id: "EE", name: "Estonia" },
  { _id: "ET", name: "Ethiopia" },
  { _id: "XE", name: "Europa" },
  { _id: "EU", name: "European In Country" },
  { _id: "FK", name: "Falkland Islands" },
  { _id: "FO", name: "Faroe Islands" },
  { _id: "FJ", name: "Fiji" },
  { _id: "FI", name: "Finland" },
  { _id: "FR", name: "France" },
  { _id: "GF", name: "French Guiana" },
  { _id: "PF", name: "French Polynesia" },
  { _id: "TF", name: "French Southern Ter" },
  { _id: "GA", name: "Gabon" },
  { _id: "GM", name: "Gambia" },
  { _id: "GE", name: "Georgia" },
  { _id: "DE", name: "Germany" },
  { _id: "GH", name: "Ghana" },
  { _id: "GI", name: "Gibraltar" },
  { _id: "GR", name: "Greece" },
  { _id: "GL", name: "Greenland" },
  { _id: "GD", name: "Grenada" },
  { _id: "GP", name: "Guadeloupe" },
  { _id: "GU", name: "Guam" },
  { _id: "GT", name: "Guatemala" },
  { _id: "GG", name: "Guernsey Minor Ch" },
  { _id: "GN", name: "Guinea" },
  { _id: "GW", name: "Guinea-Bissau" },
  { _id: "GY", name: "Guyana" },
  { _id: "HT", name: "Haiti" },
  { _id: "HM", name: "Heard Island" },
  { _id: "VA", name: "Holy See" },
  { _id: "HN", name: "Honduras" },
  { _id: "HK", name: "Hong Kong" },
  { _id: "HU", name: "Hungary" },
  { _id: "IS", name: "Iceland" },
  { _id: "IN", name: "India" },
  { _id: "ID", name: "Indonesia" },
  { _id: "IR", name: "Iran" },
  { _id: "IQ", name: "Iraq" },
  { _id: "IE", name: "Ireland" },
  { _id: "IM", name: "Isle of Man" },
  { _id: "IL", name: "Israel" },
  { _id: "IT", name: "Italy" },
  { _id: "JM", name: "Jamaica" },
  { _id: "JP", name: "Japan" },
  { _id: "JE", name: "Jersey" },
  { _id: "JO", name: "Jordan" },
  { _id: "KZ", name: "Kazakstan" },
  { _id: "KE", name: "Kenya" },
  { _id: "KI", name: "Kiribati" },
  { _id: "KP", name: "North Korea" },
  { _id: "KR", name: "South Korea" },
  { _id: "KW", name: "Kuwait" },
  { _id: "KG", name: "Kyrgyzstan" },
  { _id: "LA", name: "Laos" },
  { _id: "LV", name: "Latvia" },
  { _id: "LB", name: "Lebanon" },
  { _id: "LS", name: "Lesotho" },
  { _id: "LR", name: "Liberia" },
  { _id: "LY", name: "Libya " },
  { _id: "LI", name: "Liechtenstein" },
  { _id: "LT", name: "Lithuania" },
  { _id: "LU", name: "Luxembourg" },
  { _id: "MO", name: "Macau" },
  { _id: "MK", name: "Macedonia" },
  { _id: "MG", name: "Madagascar" },
  { _id: "MW", name: "Malawi" },
  { _id: "MY", name: "Malaysia" },
  { _id: "MV", name: "Maldives" },
  { _id: "ML", name: "Mali" },
  { _id: "MT", name: "Malta" },
  { _id: "MH", name: "Marshall Island" },
  { _id: "MQ", name: "Martinique" },
  { _id: "MR", name: "Mauritania" },
  { _id: "MU", name: "Mauritius" },
  { _id: "YT", name: "Mayotte" },
  { _id: "MX", name: "Mexico" },
  { _id: "FM", name: "Micronesia " },
  { _id: "MD", name: "Moldova, Republic of" },
  { _id: "MC", name: "Monaco" },
  { _id: "MN", name: "Mongolia" },
  { _id: "MS", name: "Monserrat" },
  { _id: "ME", name: "Montenegro" },
  { _id: "MA", name: "Morocco" },
  { _id: "MZ", name: "Mozambique" },
  { _id: "MM", name: "Myanmar" },
  { _id: "NA", name: "Namibia" },
  { _id: "NR", name: "Nauru" },
  { _id: "NP", name: "Nepal" },
  { _id: "NL", name: "Netherlands" },
  { _id: "AN", name: "Netherlands Antilles" },
  { _id: "NC", name: "New Caledonia" },
  { _id: "NZ", name: "New Zealand" },
  { _id: "NI", name: "Nicaragua" },
  { _id: "NE", name: "Niger" },
  { _id: "NG", name: "Nigeria" },
  { _id: "NU", name: "Niue" },
  { _id: "NF", name: "Norfolk Island" },
  { _id: "MP", name: "Mariana Islands" },
  { _id: "NO", name: "Norway" },
  { _id: "OM", name: "Oman" },
  { _id: "PK", name: "Pakistan" },
  { _id: "PW", name: "Palau" },
  { _id: "PS", name: "Palestine" },
  { _id: "PA", name: "Panama" },
  { _id: "PG", name: "Papua New Guinea" },
  { _id: "PY", name: "Paraguay" },
  { _id: "CN", name: "Rep of China" },
  { _id: "PE", name: "Peru" },
  { _id: "PH", name: "Philippines" },
  { _id: "PN", name: "Pitcairn" },
  { _id: "PI", name: "PLATINUM" },
  { _id: "PL", name: "Poland" },
  { _id: "PT", name: "Portugal" },
  { _id: "PR", name: "Puerto Rico" },
  { _id: "QA", name: "Qatar" },
  { _id: "TW", name: "Rep of China(Taiwan)" },
  { _id: "RE", name: "Reunion" },
  { _id: "RO", name: "Romania" },
  { _id: "RU", name: "Russian Federation" },
  { _id: "RW", name: "Rwanda" },
  { _id: "BL", name: "Saint Barthelemy" },
  { _id: "KN", name: "Saint Kitts Nevis" },
  { _id: "LC", name: "Saint Lucia" },
  { _id: "SX", name: "Saint Maarten(Dutch)" },
  { _id: "MF", name: "Saint Martin(French)" },
  { _id: "WS", name: "Samoa" },
  { _id: "SM", name: "San Marino" },
  { _id: "ST", name: "Sao Tome Principe" },
  { _id: "SA", name: "Saudi Arabia" },
  { _id: "SN", name: "Senegal" },
  { _id: "RS", name: "Serbia, Republic of" },
  { _id: "SC", name: "Seychelles" },
  { _id: "SL", name: "Sierra Leone" },
  { _id: "XS", name: "Silver" },
  { _id: "SS", name: "SILVER" },
  { _id: "SG", name: "Singapore" },
  { _id: "SK", name: "Slovakia" },
  { _id: "SI", name: "Slovenia" },
  { _id: "SB", name: "Solomon Islands" },
  { _id: "SO", name: "Somalia" },
  { _id: "ZA", name: "South Africa" },
  { _id: "GS", name: "South Georgia " },
  { _id: "ES", name: "Spain" },
  { _id: "LK", name: "Sri Lanka" },
  { _id: "SH", name: "St. Helena" },
  { _id: "PM", name: "St Pierre & Miquelon" },
  { _id: "VC", name: "St Vincent Grenadine" },
  { _id: "QQ", name: "Stateless" },
  { _id: "SP", name: "Stateless Persons" },
  { _id: "SD", name: "Sudan" },
  { _id: "SR", name: "Suriname" },
  { _id: "SJ", name: "Svalbard Jan Mayen" },
  { _id: "SZ", name: "Swaziland" },
  { _id: "SE", name: "Sweden" },
  { _id: "CH", name: "Switzerland" },
  { _id: "SY", name: "Syrian Arab Republic" },
  { _id: "TJ", name: "Tajikistan" },
  { _id: "TZ", name: "Tanzania" },
  { _id: "TH", name: "Thailand" },
  { _id: "TL", name: "Timor-Leste" },
  { _id: "TG", name: "Togo" },
  { _id: "TK", name: "Tokelau" },
  { _id: "TO", name: "Tonga" },
  { _id: "TT", name: "Trinidad and Tobago" },
  { _id: "TN", name: "Tunisia" },
  { _id: "TR", name: "Turkey" },
  { _id: "TM", name: "Turkmenistan" },
  { _id: "TC", name: "Turks and Caicos " },
  { _id: "TV", name: "Tuvalu" },
  { _id: "UG", name: "Uganda" },
  { _id: "UA", name: "Ukraine" },
  { _id: "AE", name: "United Arab Emirates" },
  { _id: "GB", name: "United Kingdom of GB and NI" },
  { _id: "UM", name: "United StatesMinor" },
  { _id: "US", name: "United States" },
  { _id: "UY", name: "Uruguay" },
  { _id: "YU", name: "USE RS(SERBIA)" },
  { _id: "UZ", name: "Uzbekistan" },
  { _id: "VU", name: "Vanuatu" },
  { _id: "VE", name: "Venezuela" },
  { _id: "VN", name: "Vietnam" },
  { _id: "VI", name: "Virgin Islands, U.S." },
  { _id: "WF", name: "Wallis et Futuna" },
  { _id: "EH", name: "Western Sahara" },
  { _id: "XX", name: "Worldwide" },
  { _id: "YE", name: "Yemen" },
  { _id: "ZM", name: "Zambia" },
  { _id: "ZW", name: "Zimbabwe" },
];

export const BANKS = [
  {
    _id: "Abu Dhabi Commercial Bank",
    name: "Abu Dhabi Commercial Bank",
    routingCode: "600310101"
  },
  {
    _id: "Abu Dhabi Islamic Bank",
    name: "Abu Dhabi Islamic Bank",
    routingCode: "405010101"
  },
  {
    _id: "Ajman Bank",
    name: "Ajman Bank",
    routingCode: "805740101"
  },
  {
    _id: "Al Hilal Bank",
    name: "Al Hilal Bank",
    routingCode: "105310101"
  },
  {
    _id: "Al Maryah Community Bank",
    name: "Al Maryah Community Bank",
    routingCode: "009710001"
  },
  {
    _id: "Al Masraf Arab Bank for Investment & Foreign Trade",
    name: "Al Masraf Arab Bank for Investment & Foreign Trade",
    routingCode: "100810101"
  },
  {
    _id: "Bank of Sharjah",
    name: "Bank of Sharjah",
    routingCode: "401230101"
  },
  {
    _id: "Commercial Bank International",
    name: "Commercial Bank International",
    routingCode: "2220101"
  },
  {
    _id: "Commercial Bank of Dubai",
    name: "Commercial Bank of Dubai",
    routingCode: "102320150"
  },
  {
    _id: "Dubai Bank",
    name: "Dubai Bank",
    routingCode: "5120101"
  },
  {
    _id: "Dubai Islamic Bank",
    name: "Dubai Islamic Bank",
    routingCode: "802420101"
  },
  {
    _id: "Emirates Investment Bank",
    name: "Emirates Investment Bank",
    routingCode: "4820101"
  },
  {
    _id: "Emirates Islamic",
    name: "Emirates Islamic",
    routingCode: "703420114"
  },
  {
    _id: "Emirates NBD",
    name: "Emirates NBD",
    routingCode: "302620122"
  },
  {
    _id: "First Abu Dhabi Bank",
    name: "First Abu Dhabi Bank",
    routingCode: "803510106"
  },
  {
    _id: "Invest Bank",
    name: "Invest Bank",
    routingCode: "503030102"
  },
  {
    _id: "Mashreq",
    name: "Mashreq",
    routingCode: "203320101"
  },
  {
    _id: "National Bank of Fujairah",
    name: "National Bank of Fujairah",
    routingCode: "703820101"
  },
  {
    _id: "National Bank of Ras Al-Khaimah PJSC (RAKBANK)",
    name: "National Bank of Ras Al-Khaimah PJSC (RAKBANK)",
    routingCode: "104060106"
  },
  {
    _id: "National Bank of Umm Al-Quwain",
    name: "National Bank of Umm Al-Quwain",
    routingCode: "104251001"
  },
  {
    _id: "Noor Bank",
    name: "Noor Bank",
    routingCode: "905220101"
  },
  {
    _id: "Sharjah Islamic Bank",
    name: "Sharjah Islamic Bank",
    routingCode: "404130101"
  },
  {
    _id: "United Arab Bank",
    name: "United Arab Bank",
    routingCode: "904630101"
  },
  {
    _id: "United Bank Ltd.",
    name: "United Bank Ltd.",
    routingCode: "604720106"
  },
  {
    _id: "Standard Chartered Bank",
    name: "Standard Chartered Bank",
    routingCode: "504420120"
  },
  {
    _id: "Saudi National Bank",
    name: "Saudi National Bank",
    routingCode: "605520101"
  },
  {
    _id: "Rafidain Bank",
    name: "Rafidain Bank",
    routingCode: "400510101"
  },
  {
    _id: "National Bank of Oman",
    name: "National Bank of Oman",
    routingCode: "903910101"
  },
  {
    _id: "National Bank of Kuwait",
    name: "National Bank of Kuwait",
    routingCode: "505620101"
  },
  {
    _id: "National Bank of Bahrain",
    name: "National Bank of Bahrain",
    routingCode: "203610101"
  },
  {
    _id: "MCB Bank Limited",
    name: "MCB Bank Limited",
    routingCode: "209120101"
  },
  {
    _id: "KEB Hana Bank",
    name: "KEB Hana Bank",
    routingCode: "408910101"
  },
  {
    _id: "Janata Bank Limited",
    name: "Janata Bank Limited",
    routingCode: "103110110"
  },
  {
    _id: "Intesa Sanpaolo S.P.A",
    name: "Intesa Sanpaolo S.P.A",
    routingCode: "309314334"
  },
  {
    _id: "Industrial & Commercial Bank of China",
    name: "Industrial & Commercial Bank of China",
    routingCode: "804310101"
  },
  {
    _id: "HSBC Bank Middle East Limited",
    name: "HSBC Bank Middle East Limited",
    routingCode: "302020120"
  },
  {
    _id: "Habib Bank Ltd.",
    name: "Habib Bank Ltd.",
    routingCode: "102820111"
  },
  {
    _id: "Habib Bank A.G Zurich",
    name: "Habib Bank A.G Zurich",
    routingCode: "302920101"
  },
  {
    _id: "Gulf International Bank",
    name: "Gulf International Bank",
    routingCode: "509210001"
  },
  {
    _id: "El Nilein Bank",
    name: "El Nilein Bank",
    routingCode: "2510101"
  },
  {
    _id: "Doha Bank",
    name: "Doha Bank",
    routingCode: "705420101"
  },
  {
    _id: "Deutsche Bank AG",
    name: "Deutsche Bank AG",
    routingCode: "204910101"
  },
  {
    _id: "Credit Agricloe",
    name: "Credit Agricloe",
    routingCode: "301620101"
  },
  {
    _id: "CitiBank N. A.",
    name: "CitiBank N. A.",
    routingCode: "102120101"
  },
  {
    _id: "BNP Paribas",
    name: "BNP Paribas",
    routingCode: "401810101"
  },
  {
    _id: "Barclays Bank PLC",
    name: "Barclays Bank PLC",
    routingCode: "401920110"
  },
  {
    _id: "Banque Misr",
    name: "Banque Misr",
    routingCode: "1510102"
  },
  {
    _id: "Bank Saderat Iran",
    name: "Bank Saderat Iran",
    routingCode: "901320124"
  },
  {
    _id: "Bank of China Limited",
    name: "Bank of China Limited",
    routingCode: "309010188"
  },
  {
    _id: "Bank of Baroda",
    name: "Bank of Baroda",
    routingCode: "801120101"
  },
  {
    _id: "Bank Melli Iran",
    name: "Bank Melli Iran",
    routingCode: "901020101"
  },
  {
    _id: "Bank Al Falah Limited",
    name: "Bank Al Falah Limited",
    routingCode: "9920501"
  },
  {
    _id: "Arab Bank",
    name: "Arab Bank",
    routingCode: "910101"
  },
  {
    _id: "Arab African International Bank",
    name: "Arab African International Bank",
    routingCode: "900720101"
  },
  {
    _id: "Al Khaliji",
    name: "Al Khaliji",
    routingCode: "201720101"
  },
  {
    _id: "Al Ahli Bank of Kuwait",
    name: "Al Ahli Bank of Kuwait",
    routingCode: "200420101"
  },
  {
    _id: "Agricultural Bank of China Ltd.",
    name: "Agricultural Bank of China Ltd.",
    routingCode: "709820785"
  },
  {
    _id: "Bank of America, N.A. (Canada)",
    name: "Bank of America, N.A. (Canada)",
    routingCode: "000910101"
  },
  {
    _id: "Royal Bank of Canada",
    name: "Royal Bank of Canada",
    routingCode: "002220101"
  },
  {
    _id: "The Bank of Nova Scotia",
    name: "The Bank of Nova Scotia",
    routingCode: "002620132"
  },
  {
    _id: "Canadian Imperial Bank of Commerce",
    name: "Canadian Imperial Bank of Commerce",
    routingCode: "009710001"
  },
  {
    _id: "FirstBank",
    name: "FirstBank",
    routingCode: "102710102"
  },
  {
    _id: "HSBC Bank USA",
    name: "HSBC Bank USA",
    routingCode: "202620103"
  },
  {
    _id: "Regions Bank",
    name: "Regions Bank",
    routingCode: "205010141"
  },
  {
    _id: "BancFirst",
    name: "BancFirst",
    routingCode: "305012577"
  },
  {
    _id: "Bank of Oklahoma, N.A.",
    name: "Bank of Oklahoma, N.A.",
    routingCode: "305031064"
  },
  {
    _id: "PNC Bank, N.A.",
    name: "PNC Bank, N.A.",
    routingCode: "502320103"
  },
  {
    _id: "TCF National Bank",
    name: "TCF National Bank",
    routingCode: "503870102"
  },
  {
    _id: "First Hawaiian Bank",
    name: "First Hawaiian Bank",
    routingCode: "600930105"
  },
  {
    _id: "Bank of America, N.A. (Hawaii)",
    name: "Bank of America, N.A. (Hawaii)",
    routingCode: "602440109"
  },
  {
    _id: "Charles Schwab Bank, SSB",
    name: "Charles Schwab Bank, SSB",
    routingCode: "643140101"
  },
  {
    _id: "Deutsche Bank Trust Company Americas",
    name: "Deutsche Bank Trust Company Americas",
    routingCode: "704510131"
  },
  {
    _id: "Commerce Bank",
    name: "Commerce Bank",
    routingCode: "705010403"
  },
  {
    _id: "UMB Bank, N.A.",
    name: "UMB Bank, N.A.",
    routingCode: "705020103"
  },
  {
    _id: "U.S. Bank National Association",
    name: "U.S. Bank National Association",
    routingCode: "705040116"
  },
  {
    _id: "First Financial Bank, N.A.",
    name: "First Financial Bank, N.A.",
    routingCode: "740810000"
  },
  {
    _id: "Zions First National Bank",
    name: "Zions First National Bank",
    routingCode: "803720101"
  },
  {
    _id: "U.S. Bank National Association",
    name: "U.S. Bank National Association",
    routingCode: "804020101"
  }
];

export const DOTNET_CARD_STATUS = [
  { id: '1', description: "New Employee" },
  { id: '2', description: "Send to Compliance KP" },
  { id: '3', description: "Compliance KP Passed" },
  { id: '4', description: "Compliance KP Failed" },
  { id: '5', description: "Send To Bank" },
  { id: '6', description: "Compliance Bank Passed" },
  { id: '7', description: "Compliance Bank Failed" },
  { id: '8', description: "Export To CBF - NI" },
  { id: '9', description: "CBF Feedback Passed - NI" },
  { id: '10', description: "CBF Feedback Failed - NI" },
  { id: '11', description: "Export to Processor" },
  { id: '12', description: "Account Received" },
  { id: '13', description: "Account Creation Failed" },
  { id: '14', description: "Card Creation From Persou" },
  { id: '15', description: "File Send To Persou" },
  { id: '16', description: "Card Printed" },
  { id: '17', description: "Card Received From Persou" },
  { id: '18', description: "Card Received in H.O" },
  { id: '19', description: "Assigned to Executive" },
  { id: '20', description: "Person Not Available" },
  { id: '21', description: "Request for Location Change" },
  { id: '22', description: "Card Activation Request Send" },
  { id: '23', description: "Card Delivered" },
  { id: '24', description: "Card Activated" }
]

export const BENFICIARY_TRANSACTION_TYPE = [
  { _id: 'BILL_PAYMENT', name: 'Bill Payment' },
  { _id: 'REMITTANCE', name: 'Remitance' },
  { _id: 'TOPUP', name: 'Top Up' },
]

export const MOBILE_USER_STATUS = {
  OTP_NOT_VERIFIED: 'OTP_NOT_VERIFIED',
  INACTIVE: 'INACTIVE',
  ACTIVE: 'ACTIVE'
}

export const PREPAID_WPS = [
  {
    _id: 1,
    name: "Prepaid",
  },
  {
    _id: 2,
    name: "WPS",
  },
];

export const BUSINESS_OWNER_TYPE_CODE_OBJ = {
  Ent: 'Entity',
  Ind: 'Individual'
};