import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CButton } from "../../uiComponents";
import { updateMobileUserPhone, updateMobileUserPhoneOTP } from "../../store/actions/Employees.action";
import { MdOutlinePhoneIphone } from "react-icons/md";
import { ConditionalRendering } from "../../utils/methods";
import OTPConfirmation from "./OTPConfirmation";
import AddPhone from "./AddPhone";
import { MOBILE_USER_STATUS } from "../../utils/constants";

const CUpdateMobileUserPhoneNo = ({ employee, mobileUser, client, getEmployee }) => {

    const dispatch = useDispatch();

    const reduxState = useSelector(({ employees }) => {
        return {
            otpLoading: employees.updateMobileUserPhoneOTPLoading,
            holdingResponse: employees.updateMobileUserPhoneOTPHoldingResponse,
            updateLoading: employees.updateMobileUserPhoneLoading,

        };
    });

    const { otpLoading, holdingResponse, updateLoading } = reduxState;

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenOTP, setIsOpenOTP] = useState(false);

    const callBack = (res) => {
        if (res?.mobileAppToken) {
            setIsOpenOTP(true);
        }
    }

    const getOTP = (values) => {
        const payload = {
            employee: employee?._id,
            client: client?._id,
            phoneNumber: `${'971'}${values?.phoneNumber}`,
        }
        dispatch(updateMobileUserPhoneOTP(payload, callBack));
    }

    const updateUserCallBack = () => {
        getEmployee && getEmployee();
        setIsOpen(false);
        setIsOpenOTP(false);
    }

    const updateMobileAppUserPhone = (values) => {
        const payload = {
            employee: employee?._id,
            client: client?._id,
            mobileAppToken: holdingResponse?.mobileAppToken,
            otp: values?.otp,
        }
        dispatch(updateMobileUserPhone(payload, updateUserCallBack));
    }
    return <>
        <ConditionalRendering
            condition={mobileUser?.status === MOBILE_USER_STATUS?.ACTIVE && !mobileUser?.isLightUser}
            children={<CButton
                containerClass="flex0"
                buttonType="gradient"
                title="Update Mobile User Phone No"
                disabled={updateLoading || otpLoading}
                loading={updateLoading || otpLoading}
                onClick={() => setIsOpen(true)}
                leftIcon={(className) => <MdOutlinePhoneIphone className={className} />}
            />}
        />

        <AddPhone isOpen={isOpen} setIsOpen={setIsOpen} getOTP={getOTP} loading={otpLoading || updateLoading} />
        <OTPConfirmation title='Enter OTP to verify user' isOpen={isOpenOTP} setIsOpen={setIsOpenOTP} onSubmitOTP={updateMobileAppUserPhone} loading={otpLoading || updateLoading} />
    </>
};

export default CUpdateMobileUserPhoneNo;