import LoginHistoryConstant from "../constants/LoginHistory.constant";

const initialState = {
    loginHistory: [],
    getLoginHistoryLoading: false,
    loginHistoryMetaData: {},
};

export default (state = initialState, action = {}) => {
    switch (action.type) {

        case LoginHistoryConstant.GET_LOGIN_HISTORY:
        return {
                ...state,
                loginHistory: action.data,
                getLoginHistoryLoading: action.loading,
                loginHistoryMetaData: action.metaData,
            };

        default:
            return state;
    }
};
