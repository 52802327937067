import EXCHANGE_HOUSE_APPROVAL from "../constants/ExchangeApproval.constant";

const initialState = {
    getLoading: false,
    approvals: [],
    approvalsMetaData: {},

    approveRejectLoading: false,

    employeersList: [],
    getEmployeersLoading: false,

    getSingleApprovalLoading: false,
    approval: {},

    uploadEstLoading: false,

};

export default (state = initialState, action = {}) => {
    switch (action.type) {

        case EXCHANGE_HOUSE_APPROVAL.GET_EH_APPROVALS:
            return {
                ...state,
                getLoading: action.loading,
                approvals: action.data || state.approvals,
                approvalsMetaData: action.metaData || state.approvalsMetaData
            };

        case EXCHANGE_HOUSE_APPROVAL.EH_APPROVE_REJECT:
            return {
                ...state,
                approveRejectLoading: action.loading,
            };

        case EXCHANGE_HOUSE_APPROVAL.GET_EH_EMPLOYEERS_LIST:
            return {
                ...state,
                employeersList: action.data,
                getEmployeersLoading: action.loading,
            };

        case EXCHANGE_HOUSE_APPROVAL.GET_EH_SINGLE_APPROVAL:
            return {
                ...state,
                getSingleApprovalLoading: action.loading,
                approval: action.data,
            };

        case EXCHANGE_HOUSE_APPROVAL.UPLOAD_ESTABLISHMENTS:
            return {
                ...state,
                uploadEstLoading: action.loading,
            };

        case EXCHANGE_HOUSE_APPROVAL.UPDATE_EH_APPROVAL_SOCKET:
            return {
                ...state,
                approvals: action.data,
            };


        default:
            return state;
    }
};
