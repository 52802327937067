let sdk = null;
let docType = null;

const createUqudoSdk = async (options) => {
    if (!sdk) {
        const { default: UqudoSdkFactory, DocumentType } = await import("uqudosdk-web");
        sdk = UqudoSdkFactory.create(options);
        docType = DocumentType;
        return { sdk, docType };
    }
    return { sdk, docType };
};

export default createUqudoSdk;