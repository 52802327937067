import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CButton } from "../../uiComponents";
import { MdOutlineFileUpload } from "react-icons/md";
import { ConditionalRendering, handleError } from "../../utils/methods";
import UploadForm from "./UploadForm";
import { uploadKYC } from "../../store/actions/Employees.action";

const CUploadKYC = ({ employee, client }) => {

    const dispatch = useDispatch();

    const reduxState = useSelector(({ employees }) => {
        return {
            loading: employees.uploadKYCLoading,
        };
    });

    const { loading } = reduxState;

    const [isOpen, setIsOpen] = useState(false);

    const callBack = () => {
        setIsOpen(false);
    }

    const uploadKYCFunc = (fileObj) => {
        let body = new FormData();
        body.append('client', client);
        body.append('employee', employee);

        if (fileObj && Object.keys(fileObj)?.length) {
            body.append('kycDocument', fileObj);
        } else {
            handleError('Please upload file!');
        }
        dispatch(uploadKYC(body, callBack));
    }

    return <>
        <ConditionalRendering
            condition={true}
            children={
                <CButton
                    containerClass="flex0"
                    buttonType="gradient"
                    title="Upload KYC"
                    disabled={loading}
                    loading={loading}
                    onClick={() => setIsOpen(true)}
                    leftIcon={(className) => <MdOutlineFileUpload className={className} />}
                />
            }
        />
        <UploadForm isOpen={isOpen} setIsOpen={setIsOpen} loading={loading} uploadKYCFunc={uploadKYCFunc} />
    </>
};

export default CUploadKYC;