import LOGS from "../constants/Logs.constant";

const initialState = {
    logs: [],
    getLoading: false,
    metaData: {},
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case LOGS.GET_LOGS:
            return {
                ...state,
                logs: action.data,
                getLoading: action.loading,
                metaData: action.metaData,
            };

        default:
            return state;
    }
};
