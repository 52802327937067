import React, { useEffect, useRef } from "react";
import { ApplyAdvanceSalaryScheme } from "./Validations";
import { Form, Formik } from "formik";
import { RELATION_HOME, RELATION_UAE, getEligibleAdvanceAmount } from "./helper";
import { countryData } from "../../utils/countryData";
import { formatter, getPhoneNumberWithOutCode } from "../../utils/methods";
import { CButton, CField } from "../../uiComponents";

const FormAdvanceSalary = ({ loading, employee, advanceSalaryDetails, onFinish, localCountry, setLocalCountry, inHomeCountry, setInHomeCountry }) => {

    const form = useRef(null);
    const nationality = employee?.nationality;
    const currentUserNumber = employee?.mobileNo;
    let currentCountry = 'AE';

    useEffect(() => {
        let foundInHomeCountry = findCountryWithISO(countryData, nationality);
        let foundLocalCountry = findCountryWithISO(countryData, currentCountry);
        setInHomeCountry(foundInHomeCountry);
        setLocalCountry(foundLocalCountry)
    }, [employee])

    const findCountryWithISO = (array, iso) => {
        return iso ? array?.find(obj => obj?.iso === iso) : {};
    }

    const getValidation = (advanceSalary) => {
        let min = 0, minMessage = '', max = 0, maxMessage = '';
        if (advanceSalary?.feesBrackets?.length) {
            min = Math.min(...advanceSalary?.feesBrackets.map(item => item?.fromAmount || 0));
            max = getEligibleAdvanceAmount(advanceSalary);
            if (min === 0) {
                min = 100
            }
        }
        minMessage = `Amount should contain atleast ${min}`;
        maxMessage = `Amount should not be more than ${max}`;

        return { min, minMessage, max, maxMessage }
    };

    const generateValidation = () => {
        return ApplyAdvanceSalaryScheme({ currentUserNumber, advance: getValidation(advanceSalaryDetails), localCountry, inHomeCountry })
    };

    return <Formik
        innerRef={form}
        validationSchema={generateValidation()}
        validateOnChange={true}
        initialValues={{}}
        onSubmit={onFinish}
    >
        {({ errors, touched, handleSubmit, setFieldValue, values, setFieldTouched, submitCount }) => (
            <Form className="c-form inline-form-2 margin-right_zero margin-left_zero" >

                <div className="d-flex justify-between align-center" >
                    <p>Eligible Advance Amount</p>
                    <p style={{ fontWeight: 'bold' }} >{formatter(advanceSalaryDetails?.eligibleAdvanceAmount)}</p>
                </div>

                <hr />
                <CField
                    name="amount"
                    leftIcon="Dashboard-Icons_phone"
                    containerClass='w-100 flex-auto margin-right_zero'
                    placeholder="Amount"
                    type="number"
                    error={submitCount ? errors?.amount : touched?.amount && errors.amount}
                    onBlur={() => setFieldTouched("amount", true, true)}
                    disabled={loading}
                    value={values?.amount}
                    onChange={(e) => setFieldValue("amount", e.target.value)}
                />
                <hr />

                <CField
                    name="firstReferenceRelation"
                    isLabel="Relationship with Contact Person (In Home Country)"
                    placeholder="Select here"
                    fieldType="select"
                    containerClass='w-100 flex-auto margin-right_zero'
                    loading={loading}
                    onBlur={() => setFieldTouched("firstReferenceRelation", true, true)}
                    error={submitCount ? errors?.firstReferenceRelation : touched?.firstReferenceRelation && errors.firstReferenceRelation}
                    data={RELATION_HOME}
                    value={values?.firstReferenceRelation}
                    onChange={(val) => setFieldValue("firstReferenceRelation", val)}
                />

                <CField
                    name="firstReferenceFullName"
                    leftIcon="Dashboard-Icons_phone"
                    containerClass='w-100 flex-auto margin-right_zero'
                    placeholder="Contact Person Name (In Home Country)"
                    type="text"
                    error={submitCount ? errors?.firstReferenceFullName : touched?.firstReferenceFullName && errors.firstReferenceFullName}
                    onBlur={() => setFieldTouched("firstReferenceFullName", true, true)}
                    disabled={loading}
                    value={values?.firstReferenceFullName}
                    onChange={(e) => setFieldValue("firstReferenceFullName", e.target.value)}
                />

                <CField
                    name="firstReferencePhone"
                    placeholder="Contact Person Phone Number (In Home Country)"
                    type="text"
                    prefix={<span>{inHomeCountry?.code}</span>}
                    containerClass='w-100 flex-auto margin-right_zero'
                    error={submitCount ? errors?.firstReferencePhone : touched?.firstReferencePhone && errors.firstReferencePhone}
                    onBlur={() => setFieldTouched("firstReferencePhone", true, true)}
                    value={values?.firstReferencePhone}
                    onChange={(e) => {
                        let phone = e.target.value;
                        let reg = /^0+/gi;
                        if (phone.match(reg)) {
                            phone = phone.replace(reg, '');
                        }
                        setFieldValue("firstReferencePhone", phone)
                    }}
                />

                <hr />

                <CField
                    name="localFriendRelation"
                    isLabel="Relationship with Contact Person (In UAE)"
                    placeholder="Select here"
                    fieldType="select"
                    containerClass='w-100 flex-auto margin-right_zero'
                    loading={loading}
                    onBlur={() => setFieldTouched("localFriendRelation", true, true)}
                    error={submitCount ? errors?.localFriendRelation : touched?.localFriendRelation && errors.localFriendRelation}
                    data={RELATION_UAE}
                    value={values?.localFriendRelation}
                    onChange={(val) => setFieldValue("localFriendRelation", val)}
                />

                <CField
                    name="localFriendFullName"
                    leftIcon="Dashboard-Icons_phone"
                    containerClass='w-100 flex-auto margin-right_zero'
                    placeholder="Contact Person Name (In UAE)"
                    type="text"
                    error={submitCount ? errors?.localFriendFullName : touched?.localFriendFullName && errors.localFriendFullName}
                    onBlur={() => setFieldTouched("localFriendFullName", true, true)}
                    disabled={loading}
                    value={values?.localFriendFullName}
                    onChange={(e) => setFieldValue("localFriendFullName", e.target.value)}
                />

                <CField
                    name="localFriendPhone"
                    placeholder="Contact Person Phone Number (In UAE)"
                    prefix={<span>+971</span>}
                    type="text"
                    containerClass='w-100 flex-auto margin-right_zero'
                    error={submitCount ? errors?.localFriendPhone : touched?.localFriendPhone && errors.localFriendPhone}
                    onBlur={() => setFieldTouched("localFriendPhone", true, true)}
                    value={values?.localFriendPhone}
                    onChange={(e) => {
                        let phone = e.target.value;
                        let reg = /^0+/gi;
                        if (phone.match(reg)) {
                            phone = phone.replace(reg, '');
                        }
                        setFieldValue("localFriendPhone", phone)
                    }}
                />

                <CButton
                    containerClass="w-100 flex-auto margin-right_zero"
                    buttonLayout="secondary"
                    onClick={handleSubmit}
                    type="submit"
                    title="Submit"
                />
            </Form>
        )}
    </Formik>
};

export default FormAdvanceSalary;
