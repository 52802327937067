import dayjs from "dayjs";
import { formatter } from "../../utils/methods";
import FormAdvanceSalary from "./FormAdvanceSalary";

export const RELATION_HOME = [
    {
        _id: 'Husband/wife',
        name: 'Husband/Wife',
    },
    {
        _id: 'Father/Mother',
        name: 'Father/Mother',
    },
    {
        _id: 'Brother',
        name: 'Brother',
    },
    {
        _id: 'Friend',
        name: 'Friend',
    },
];

export const RELATION_UAE = [
    {
        _id: 'Relative',
        name: 'Relative',
    },
    {
        _id: 'Room Mate',
        name: 'Room Mate',
    },
    {
        _id: 'Friend',
        name: 'Friend',
    },
    {
        _id: 'Co-Worker',
        name: 'Co-Worker',
    },
    {
        _id: 'Husband/wife',
        name: 'Husband/Wife',
    },
];

export const getAdvanceSalaryDetails = (obj) => {
    if (obj?.status) {
        switch (obj?.status) {
            case "01":
                return {
                    badge: 'Apply Now!',
                    subTitle: `Your Eligible Amount is : ${formatter(obj?.eligibleAdvanceAmount, 'AED')}.`,
                    component: <FormAdvanceSalary />
                };
            case "02":
                return {
                    badge: 'Not Eligible',
                    subTitle: 'You are not eligible for Advance Salary. Please check back in a few days.',
                    image: require('../../assets/images/error-icon.png'),
                };
            case "03":
                return {
                    badge: 'Processing',
                    subTitle: 'Your request is in process.',
                    image: require('../../assets/images/warning-icon.png'),
                };
            case "04":
                return {
                    badge: 'Approved',
                    subTitle: `Due date ${dayjs(obj?.dueDate).format('DD-MM-YYYY')} or next salary date.`,
                    image: require('../../assets/images/accept-icon.png'),
                };
            case "05":
                return {
                    badge: 'Error',
                    subTitle: 'Please call customer support to apply.',
                    image: require('../../assets/images/error-icon.png'),
                };
            default:
                return null;
        }
    } else {
        return {
            badge: 'Error',
            subTitle: obj?.message,
            image: require('../../assets/images/error-icon.png'),
        };
    }
};

export const getAdvanceFees = (feesBracket, amount) => {
    if (feesBracket?.length) {
        return feesBracket.find((obj) => {
            if (amount >= obj?.fromAmount && amount <= obj?.toAmount) {
                return obj
            }
        })
    }
};

export const getEligibleAdvanceAmount = (advanceSalary) => {
    if (advanceSalary?.eligibleAdvanceAmount) {
        return Number(advanceSalary?.eligibleAdvanceAmount)
    } else {
        return 0
    }
};