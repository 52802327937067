import React, { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, List, Skeleton } from "antd";
import { getBranches } from "../../../store/actions/FindCardHolder.action";
import { capitalizeFirstLetter } from "../../../utils/methods";
import InfiniteScroll from "react-infinite-scroll-component";
import { icons } from "./helper";

const SelectBranch = ({ selectedData, onSelect }) => {

    const dispatch = useDispatch();

    const reduxState = useSelector(({ findCardHolder }) => {
        return {
            data: findCardHolder?.branches,
            loading: findCardHolder?.getBranchesLoading,
            metaData: findCardHolder?.branchesMetaData,
            loadMoreLoading: findCardHolder?.branchesLoadMoreLoading,
            canLoadMore: findCardHolder?.BranchesCanLoadMore,
        };
    });

    const { data = [], loading, metaData, loadMoreLoading, canLoadMore } = reduxState;

    const [page, setPage] = useState(1);
    const [limit] = useState(20);

    useLayoutEffect(() => {
        get(page);
    }, [page]);

    const get = (val) => {
        const payload = {
            page: val || 1,
            limit,
            country: selectedData?.country?.Name,
            bankType: selectedData?.bankType?.BankType ? selectedData?.bankType?.BankType : selectedData?.bankType?.Value,
            bankName: selectedData?.bankName,
        };
        dispatch(getBranches(payload));
    };

    const next = () => {
        if (metaData?.totalDocuments > data?.length) {
            setPage(page + 1)
        }
    };

    return (
        <InfiniteScroll
            className={`infinite-scroll ${loading && 'loading'}`}
            dataLength={data?.length || 0}
            next={next}
            height={400}
            hasMore={canLoadMore}
            loader={loadMoreLoading && canLoadMore && <Skeleton className="margin-top_15 margin-left_20"
                avatar
                round={false}
                paragraph={{ rows: 0 }}
                active
            />}
        >
            <List
                dataSource={data}
                loading={loading}
                loadMore={loadMoreLoading}
                className="ant-list-item-countries"
                renderItem={(item) => {
                    let icon = icons['bank'];
                    let title = item?.BranchName || 'Branch Less';
                    return <List.Item
                        key={item?.CCA2}
                        onClick={() => onSelect(item, 5)}
                    >
                        <List.Item.Meta
                            avatar={<Avatar shape="square" className="avatar-container" src={icon} />}
                            title={capitalizeFirstLetter(title)}
                        />
                    </List.Item>
                }}
            />
        </InfiniteScroll>
    );
};
export default React.memo(SelectBranch);