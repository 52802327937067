import auth from "./Auth.reducer";
import dashboard from "./Dashboard.reducer";
import employer from "./Employer.reducer";
import common from "./Common.reducer";
import approval from "./Approval.reducer";
import admin from "./Admin.reducer";
import employees from "./Employees.reducer";
import centivCard from "./CentivCard.reducer";
import salaries from "./Salaries.reducer";
import transactions from "./Transactions.reducer";
import exchangeHouse from "./ExchangeHouse.reducer";
import branches from "./Branches.reducer";
import onboardApprovals from "./OnboardApprovals.reducer";
import exchangeHouseApprovals from "./ExchangeApproval.reducer";
import advanceSalary from "./AdvanceSalary.reducer";
import manageUsers from "./MangeUsers.reducer";
import profile from "./Profile.reducer";
import salaryStatus from "./SalaryStatus.reducer";
import marketplace from "./Marketplace.reducer";
import findCardHolder from "./FindCardHolder.reducer";
import remittances from "./Remittances.reducer";
import logs from "./Logs.reducer";
import replacementCard from "./ReplacementCard.reducer";
import employerTeamMembers from "./EmployerTeamMembers.reducer";
import transactionsHistory from "./TransactionsHistory.reducer";
import scratchedCards from "./ScratchedCard.reducer";
import loginHistory from "./LoginHistory.reducer";

export default {
    auth,
    dashboard,
    employer, transactions,
    common,
    approval,
    admin,
    employees,
    centivCard,
    salaries,
    exchangeHouse,
    branches,
    onboardApprovals,
    exchangeHouseApprovals,
    advanceSalary,
    manageUsers,
    profile,
    salaryStatus,
    marketplace,
    findCardHolder,
    remittances,
    logs,
    replacementCard,
    employerTeamMembers,
    transactionsHistory,
    scratchedCards,
    loginHistory
}
