import ADMIN from "../constants/Admin.constant";

const initialState = {
    admins: [],
    getLoading: true,
    metaData: {},
    addLoading: false,
    updateLoading: false,
    toggleStatusLoading: false,
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case ADMIN.GET_API:
            return {
                ...state,
                admins: action.data || state?.admins,
                getLoading: action.loading,
                metaData: action.metaData || state?.metaData
            };
        case ADMIN.ADD_API:
            return {
                ...state,
                addLoading: action.loading
            };

        case ADMIN.UPDATE_API:
            return {
                ...state,
                updateLoading: action.loading
            };

        case ADMIN.TOGGLE_STATUS:
            return {
                ...state,
                toggleStatusLoading: action.loading
            };


        default:
            return state;
    }
};
