import { post, get } from "../../utils/apiMethods";
import AUTH from "../constants/Auth.constant";
import {
    clearLocalstorage,
    getValueIntoLocalStorage,
    setValueInToLocalStorage
} from "../../utils/asyncStorage/Functions";
import { getTokenAndSetIntoHeaders } from "../../utils/intercepter";
import { handleError, handleSuccess } from "../../utils/methods";
import { TOKEN } from "../../utils/asyncStorage/Constants";
import { refreshTokenTimer } from "./Common.action";

export const signUp = (values, CB) => (dispatch) => {
    dispatch({ type: AUTH.SIGNUP_USER_API, loading: true });
    post(`admins/create`, values)
        .then(async ({ data }) => {
            if (!data.error) {
                dispatch({ type: AUTH.SIGNUP_USER_API, loading: false });
                handleSuccess(data?.data?.message);
                CB && CB({ email: values?.email, client: values?.companyName });
            } else {
                handleError(data?.data?.message || 'Something went wrong!');
                dispatch({ type: AUTH.SIGNUP_USER_API, loading: false });
            }
        })
        .catch((error) => {
            handleError(error?.data?.message || error?.message);
            dispatch({ type: AUTH.SIGNUP_USER_API, loading: false });
        });
};

export const login = (payload, CB) => (dispatch) => {
    dispatch({ type: AUTH.LOGIN_USER_API, loading: true, isLoggedIn: false });
    post(`generic/login`, payload)
        .then(({ data }) => {
            if (!data.error) {
                dispatch({ type: AUTH.LOGIN_USER_API, loading: false, isLoggedIn: false });
                handleSuccess(data?.data?.message);
                CB && CB({
                    token: data.data.token,
                    email: data?.data?.email,
                    phoneNumber: data?.data?.phoneNumber,
                    twoFactorEnable: data?.data?.twoFactorEnable,
                    notificationPreferences: data?.data?.notificationPreferences
                });
            } else {
                handleError(data?.data?.message || "Something went wrong!");
                dispatch({ type: AUTH.LOGIN_USER_API, loading: false, isLoggedIn: false });
            }
        })
        .catch((error) => {
            dispatch({ type: AUTH.LOGIN_USER_API, loading: false, isLoggedIn: false });
            handleError(error?.data?.message || error?.message);
        });
};

export const setTwoFactorAuthentication = (payload, CB) => (dispatch) => {
    dispatch({ type: AUTH.SET_OTP_AUTH, loading: true });
    post(`generic/setTwoFactorAuthentication`, payload)
        .then(({ data }) => {
            if (!data.error) {
                handleSuccess(data?.data?.message);
                CB && CB();
                dispatch({ type: AUTH.SET_OTP_AUTH, loading: false });
            } else {
                handleError(data?.data?.message || "Something went wrong!");
                dispatch({ type: AUTH.SET_OTP_AUTH, loading: false });
            }
        })
        .catch((error) => {
            dispatch({ type: AUTH.SET_OTP_AUTH, loading: false, isLoggedIn: false });
            handleError(error?.data?.message || error?.message);
        });
};

export const loginVerifyOtp = (payload, CB) => async (dispatch) => {
    dispatch({ type: AUTH.GEN_USER_OTP, loading: true, isLoggedIn: false });
    post(`generic/verifyOTP`, payload)
        .then(async ({ data }) => {
            if (!data.error) {
                if (data?.data?.token) {
                    await setValueInToLocalStorage(TOKEN, data?.data?.token);
                    await getTokenAndSetIntoHeaders(data?.data?.token);
                    dispatch({ type: AUTH.GEN_USER_OTP, loading: false, isLoggedIn: true });
                    dispatch(getProfile())
                    handleSuccess(data?.data?.message);
                    // window.location.reload();
                }
            }
            dispatch({ type: AUTH.GEN_USER_OTP, loading: false, isLoggedIn: false });
        })
        .catch((error) => {
            dispatch({ type: AUTH.GEN_USER_OTP, loading: false, isLoggedIn: false });
            handleError(error?.data?.message || error?.message);
        });
};


export const resendOTP = (payload, CB) => async (dispatch) => {
    dispatch({ type: AUTH.RESEND_OTP_API, loading: true });
    post(`generic/resendOTP`, payload)
        .then(async ({ data }) => {
            if (!data.error) {
                handleSuccess(data?.data?.message || "OTP re-generated successfully!");
                dispatch({ type: AUTH.RESEND_OTP_API, loading: false });
                CB && CB(data?.data);
            } else {
                handleError(data?.data?.message || "Something went wrong!");
                dispatch({ type: AUTH.RESEND_OTP_API, loading: false });
            }
        })
        .catch((error) => {
            dispatch({ type: AUTH.RESEND_OTP_API, loading: false });
            handleError(error?.data?.message || error?.message);
        });
};

// export const resetPassword = (payload) => async (dispatch) => {
//     // dispatch({ type: AUTH.LOGIN_USER_API, loading: false, isLoggedIn: true });
//     dispatch({ type: AUTH.RESET_PASSWORD_API, loading: true, isLoggedIn: false });
//     post(`clients/verifyResetCode`, payload)
//         .then(async ({ data }) => {
//             if (!data.error) {
//                 await setValueInToLocalStorage(TOKEN, data.data.token);
//                 await getTokenAndSetIntoHeaders(data.data.token);
//                 handleSuccess('Password changed succesfully');
//                 dispatch({ type: AUTH.RESET_PASSWORD_API, loading: false, isLoggedIn: true, user: data?.data?.data });
//             } else {
//                 handleError(data?.data?.message || 'Something went wrong!');
//                 dispatch({ type: AUTH.RESET_PASSWORD_API, loading: false, isLoggedIn: false });
//             }
//         })
//         .catch((error) => {
//             dispatch({ type: AUTH.RESET_PASSWORD_API, loading: false, isLoggedIn: false });
//             handleError(error?.data?.message);
//         });
// };

export const addEstalishments = (payload, CB) => (dispatch) => {
    dispatch({ type: AUTH.ADD_ESHTABLISHMENTS, loading: true });
    post(`clients/addEstablishments`, payload)
        .then(async ({ data }) => {
            if (data?.error) {
                dispatch({ type: AUTH.ADD_ESHTABLISHMENTS, loading: false });
                handleError(data?.data?.message || "Something went wrong!");
            } else {
                dispatch({ type: AUTH.ADD_ESHTABLISHMENTS, loading: false });
                handleSuccess(data?.data?.message || "Success");
                CB && CB();
            }
        })
        .catch((error) => {
            dispatch({ type: AUTH.ADD_ESHTABLISHMENTS, loading: false });
            handleError(error?.message || error?.data?.message);
        });
};

export const getProfile = (CB) => async (dispatch) => {
    let token = await getValueIntoLocalStorage(TOKEN);
    if (token) {
        dispatch({ type: AUTH.GET_USER_PROFILE, loading: true, isLoggedIn: true });
        get(`admins/getProfile`)
            .then(async ({ data }) => {
                if (!data.error) {
                    dispatch({
                        type: AUTH.GET_USER_PROFILE,
                        loading: false,
                        user: data?.data,
                        accessRights: data?.data?.accessRights ? JSON.parse(data?.data?.accessRights) : [],
                        isLoggedIn: true
                    });
                    CB && CB();
                } else {
                    handleError(data?.data?.message || 'Something went wrong!');
                    dispatch({ type: AUTH.GET_USER_PROFILE, loading: false, isLoggedIn: true });
                }
            })
            .catch((error) => {
                dispatch({ type: AUTH.GET_USER_PROFILE, loading: false, isLoggedIn: false });
                handleError(error?.data?.message || error?.message);

            });
    } else {
        dispatch(logout())
    }
};

export const forgetPassword = (values, CB) => (dispatch) => {
    dispatch({ type: AUTH.FORGET_API, loading: true });
    post(`clients/forgetPassword`, values)
        .then(async ({ data }) => {
            if (!data.error) {
                handleSuccess(data?.data?.message)
                dispatch({ type: AUTH.FORGET_API, loading: false });
                CB && CB({ email: values?.email })
            }
        })
        .catch((error) => {
            handleError(error?.data?.message || error?.message);
            dispatch({ type: AUTH.FORGET_API, loading: false });

        })
};

export const resetPassword = (values, CB) => (dispatch) => {
    dispatch({ type: AUTH.RESET_PASSWORD_API, loading: true });
    post(`clients/resetPassword`, values)
        .then(async ({ data }) => {
            if (!data.error) {
                handleSuccess(data?.data?.message);
                dispatch({ type: AUTH.RESET_PASSWORD_API, loading: false, isLoggedIn: false, user: {} });
                CB && CB({ email: values?.email })
            }
        })
        .catch((error) => {
            handleError(error?.data?.message || error?.message);
            dispatch({ type: AUTH.RESET_PASSWORD_API, loading: false });
        })
}

export const changePassword = (values, CB) => (dispatch) => {
    dispatch({ type: AUTH.CHANGE_PASSWORD, loading: true });
    post(`clients/changePassword`, values)
        .then(async ({ data }) => {
            if (!data.error) {
                dispatch({ type: AUTH.CHANGE_PASSWORD, loading: false });

                handleSuccess(data?.data?.message);
                CB && CB();
            } else {
                handleError(data?.data?.message);
            }
        })

        .catch((error) => {
            handleError(error?.data?.message || "Something went wrong!");
            dispatch({ type: AUTH.CHANGE_PASSWORD, loading: false });
        });
};

export const verifyOTP = (payload, CB, type) => async (dispatch) => {
    dispatch({ type: AUTH.CHECK_OTP, loading: true, isLoggedIn: false });
    post(`clients/verifyResetCode`, payload)
        .then(async ({ data }) => {

            if (!data.error) {
                if (type === 'signup' && data?.data?.token) {
                    await setValueInToLocalStorage(TOKEN, data.data.token);
                    await getTokenAndSetIntoHeaders(data.data.token);
                    handleSuccess('Successfully login');
                    dispatch({ type: AUTH.CHECK_OTP, loading: false, isLoggedIn: true, user: data?.data?.data });
                }
                else if (type === 'forgot' || !data?.data?.token) {
                    dispatch({ type: AUTH.CHECK_OTP, loading: false, isLoggedIn: false, user: {} });
                    CB && CB({ email: payload?.email, otpResponse: data?.data?.client?.forgetPassword?.otpResponse })
                }
            } else {
                handleError(data?.data?.message || 'Something went wrong!');
                dispatch({ type: AUTH.CHECK_OTP, loading: false, isLoggedIn: false });
            }
        })
        .catch((error) => {
            dispatch({ type: AUTH.CHECK_OTP, loading: false, isLoggedIn: false });
            handleError(error?.data?.message || error?.message);
        });
};

export const updateProfile = (payload, CB) => (dispatch) => {
    let dispatchType = AUTH.UPDATE_PROFILE;
    dispatch({ type: dispatchType, loading: true });
    post(`clients/updateProfile`, payload)
        .then(async ({ data }) => {
            if (data?.error) {
                dispatch({ type: dispatchType, loading: false });
                handleError(data?.data?.message || 'Something went wrong!');
            } else {
                dispatch({ type: dispatchType, loading: false });
                handleSuccess(data?.data?.message || 'Success');
                CB && CB();
            }
        })
        .catch((error) => {
            dispatch({ type: dispatchType, loading: false });
            handleError(error?.data?.message || error?.message);
        });
};

export const updateClientDocuments = (payload, CB) => (dispatch) => {
    let dispatchType = AUTH.UPDATE_DOCS;
    dispatch({ type: dispatchType, loading: true });
    post(`clients/updateClientDocuments`, payload)
        .then(async ({ data }) => {
            if (data?.error) {
                dispatch({ type: dispatchType, loading: false });
                handleError(data?.data?.message || "Something went wrong!");
            } else {
                dispatch({ type: dispatchType, loading: false });
                handleSuccess(data?.data?.message || "Success");
                CB && CB();
            }
        })
        .catch((error) => {
            dispatch({ type: dispatchType, loading: false });
            handleError(error?.message || error?.data?.message);
        });
};

export const toggleMailer = (values, CB) => (dispatch) => {
    const dispatchType = AUTH.MAIL_RECIEVER_TOGGLE;
    dispatch({ type: dispatchType, loading: true });
    post(`generic/toggleEmail`, values)
        .then(async ({ data }) => {
            if (!data.error) {
                handleSuccess(data?.data?.message);
                dispatch({ type: dispatchType, loading: false });
                await dispatch(getProfile());
                CB && CB();
            }
        })
        .catch((error) => {
            handleError(error?.data?.message || "Something went wrong!");
            dispatch({ type: dispatchType, loading: false });
        });
};

export const topicStepperUpdate = (payload, CB) => (dispatch) => {
    const dispatchType = AUTH.TOPIC_STEPPER_UPDATE;
    dispatch({ type: dispatchType, loading: true });
    post(`admins/updateApprovalSteps`, payload)
        .then(async ({ data }) => {
            if (!data.error) {
                handleSuccess(data?.data?.message);
                dispatch({ type: dispatchType, loading: false });
                CB && CB();
            }
        })
        .catch((error) => {
            handleError(error?.data?.message || "Something went wrong!");
            dispatch({ type: dispatchType, loading: false });
        });
};

export const refreshToken = () => async (dispatch) => {
    let dispatchType = AUTH.REFRESH_TOKEN;
    dispatch({ type: dispatchType, loading: true });
    get(`generic/refreshToken`)
        .then(async ({ data }) => {
            if (data?.data?.token) {
                await setValueInToLocalStorage(TOKEN, data.data.token);
                await getTokenAndSetIntoHeaders(data.data.token);
                dispatch(refreshTokenTimer(false));
            }
            dispatch({ type: dispatchType, loading: false });
        })
        .catch((error) => {
            dispatch({ type: dispatchType, loading: false });
            dispatch(logout());
            handleError('Session expired!');
        });
};

export const updateNotificationPreference = (payload, CB) => (dispatch) => {
    const dispatchType = AUTH.UPDATE_NOTIFICATION_PREFERENCE_API;
    dispatch({ type: dispatchType, loading: true });
    post(`generic/setNotificationPreference`, payload)
        .then(({ data }) => {
            if (!data.error) {
                dispatch({ type: dispatchType, loading: false });
                handleSuccess(data?.data?.message);
                CB && CB();
            } else {
                handleError(data?.data?.message || "Something went wrong!");
                dispatch({ type: dispatchType, loading: false });
            }
        })
        .catch((error) => {
            dispatch({ type: dispatchType, loading: false, isLoggedIn: false });
            handleError(error?.data?.message || error?.message);
        });
};

export const logout = (history, status = "success", message = "Successfully logout") =>
    async (dispatch) => {
        try {
            await clearLocalstorage();
            dispatch({ type: AUTH.LOGOUT_USER_API });
            history && history("/");
        } catch (e) {

        }
    };
