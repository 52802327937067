import React, { useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, List } from "antd";
import { getTypesAndBanks } from "../../../store/actions/FindCardHolder.action";
import { formatter, readableTextCap } from "../../../utils/methods";
import InfiniteScroll from "react-infinite-scroll-component";
import { icons, modifyData } from "./helper";

const SelectTypesAndBanks = ({ country, onSelect }) => {

    const dispatch = useDispatch();

    const reduxState = useSelector(({ findCardHolder }) => {
        return {
            bankTypes: findCardHolder?.bankTypes,
            specialBanks: findCardHolder?.specialBanks,
            loading: findCardHolder?.getTypesAndBanksLoading,
        };
    });

    const { specialBanks = [], bankTypes = [], loading } = reduxState;


    useLayoutEffect(() => {
        get();
    }, []);

    const get = () => {
        const payload = {
            country: country?.Name
        };
        dispatch(getTypesAndBanks(payload));
    }

    // const onSelect = (item) => {
    // setSelected(item);
    // setSelectedData({ ...selectedData, bankType: item?.BankType ? item?.BankType : item?.Value });
    // if (isPakistan(selectedData?.country) || isIndia(selectedData?.country)) {
    //     setIsOpenInputModal(true);
    // } else {
    // setIsOpenModal(true);
    // }
    // }

    return (
        <>

            <h3 className="ant-modal-title margin-top_15" >Receiver Country</h3>

            <List className="ant-list-item-country" >
                <List.Item key={country?.CCA2} >
                    <List.Item.Meta
                        avatar={<Avatar shape="square" className="avatar-contain" src={country?.FlagPng} />}
                        title={readableTextCap(country?.Name)}
                        description={`${formatter(1)} = ${formatter(country?.singleAmountUnit, country?.Currency)}`}
                    />
                </List.Item>
            </List>

            <InfiniteScroll
                className={`infinite-scroll ${loading && 'loading'}`}
                dataLength={modifyData(bankTypes, specialBanks)?.length}
            >

                <List
                    dataSource={modifyData(bankTypes, specialBanks)}
                    loading={loading}
                    className="ant-list-item-countries"
                    renderItem={(item) => {
                        let icon = item?.BankName ? icons['bank'] : icons[item?.Value];
                        let title = item?.BankName ? `${item?.BankName} - ${item?.BankTypeName}` : item?.Name;
                        return <List.Item
                            key={item?.CCA2}
                            onClick={() => onSelect(item, 2)}
                        >
                            <List.Item.Meta
                                avatar={<Avatar shape="square" className="avatar-container" src={icon} />}
                                title={readableTextCap(title)}
                            />
                        </List.Item>
                    }}
                />
            </InfiniteScroll>

            {/* <SelectBank isOpen={isOpenModal} setIsOpen={setIsOpenModal} selectedData={selectedData} />
            <IBANorIFSC isOpen={isOpenInputModal} setIsOpen={setIsOpenInputModal} selectedData={selectedData} setIsOpenModalBank={setIsOpenModal} /> */}
        </>
    );
};

export default React.memo(SelectTypesAndBanks);