import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConditionalRendering } from "../../../utils/methods";
import { isIndia, isPakistan } from "./helper";
import { Form, Formik } from "formik";
import { CButton, CField } from "../../../uiComponents";
import { iban, ifsc } from "./Validations";
import { getBankByIBANorIFSC } from "../../../store/actions/FindCardHolder.action";

const IBANorIFSC = ({ selectedData, onSelect, goBack }) => {

    const dispatch = useDispatch();
    const form = useRef(null);
    const isPak = isPakistan(selectedData?.country?.CCA2 || selectedData?.country?.cca2);
    const isInd = isIndia(selectedData?.country?.CCA2 || selectedData?.country?.cca2);

    const reduxState = useSelector(({ findCardHolder }) => {
        return {
            loading: findCardHolder?.getBankByIBANorIFSCLoading,
        };
    });

    const { loading } = reduxState;

    const callBack = (res, iban) => {
        onSelect(res, 4, iban);
    }

    const onFinish = (values) => {
        const payload = {
            country: selectedData?.country?.Name,
            ...(isPak && { iban: values?.iban }),
            ...(isInd && { branchCode: values?.branchCode })
        }
        dispatch(getBankByIBANorIFSC(payload, (res) => callBack(res, values?.iban)))
    }

    return (
        <Formik
            innerRef={form}
            initialValues={{}}
            validationSchema={isPak ? iban : ifsc}
            onSubmit={onFinish}
        >
            {({ errors, touched, handleSubmit, values, setFieldTouched, submitCount, handleChange }) => (
                <Form className="inner-form col flex0">

                    <ConditionalRendering
                        condition={isPak}
                        children={<CField
                            containerClass='margin-right_zero'
                            disabled={loading}
                            name="iban"
                            placeholder="IBAN"
                            type="text"
                            value={values?.iban}
                            onChange={(e) => handleChange("iban")(e.target.value)}
                            onBlur={() => setFieldTouched("iban", true, true)}
                            error={submitCount ? errors?.iban : touched?.iban && errors.iban}
                        />}
                    />

                    <ConditionalRendering
                        condition={isInd}
                        children={<CField
                            containerClass='margin-right_zero'
                            disabled={loading}
                            name="branchCode"
                            placeholder="IFSC"
                            type="text"
                            value={values?.branchCode}
                            onChange={(e) => handleChange("branchCode")(e.target.value)}
                            onBlur={() => setFieldTouched("branchCode", true, true)}
                            error={submitCount ? errors?.branchCode : touched?.branchCode && errors.branchCode}
                        />}
                    />


                    <CButton
                        containerClass='margin-right_zero'
                        type="submit"
                        htmlType="submit"
                        onClick={handleSubmit}
                        title={`Submit`}
                        buttonLayout="primary"
                        loading={loading}
                        disabled={loading}
                    />
                    <CButton
                        containerClass='margin-right_zero'
                        type="button"
                        onClick={goBack}
                        title={`Skip`}
                        buttonLayout="primary-outline"
                        loading={loading}
                        disabled={loading}
                    />

                </Form>
            )}
        </Formik>
    );
};
export default React.memo(IBANorIFSC);
