import React, { Fragment } from "react";
import { Navbar } from "../index";
import { CLoading } from "../../uiComponents";
import { ConditionalRendering } from "../../utils/methods";
import { Empty } from "antd";

function PageWrapper({
  children,
  headerProps,
  background = true,
  className,
  noNavar = false,
  renderBelow,
  loadingWithOverlay = false,
  loading,
  isEmpty = false,
  emptyText = ''
}) {
  return <Fragment>
    {noNavar ? null : <Navbar {...headerProps} />}
    {renderBelow}
    <div className={`${background ? "page" : "normal-page"} ${className || ""}`}>
      <ConditionalRendering
        condition={isEmpty}
        children={<Empty
          style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', justifyContent: 'center' }}
          description={emptyText || "Please Select a Company!"}
        />}
        elseChildren={loadingWithOverlay ? <>
          <CLoading loading={loading} position={'absolute'} />
          {children}
        </>
          : loading ? <CLoading position={'absolute'} /> : children}
      />

    </div>
  </Fragment>;
}
export default React.memo(PageWrapper);
