import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Menu } from "antd";
import { CButton, CField } from "../../uiComponents";
import { useNavigate } from "react-router";
import { IoIosMenu } from "react-icons/io";
import { ImArrowLeft2 } from "react-icons/im";
import { changeClient, toggleLeftDrawer } from "../../store/actions/Common.action";

function Navbar(props) {
  const {
    title = "",
    subTitle = "",
    subTitleToTitle,
    renderRight = () => null,
    renderBelow = () => null,
    canGoBack = false,
    onClickGoBack = null,
    navbarBg,
    canRenderCompany = false,
    companyLoading = false,
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const reduxState = useSelector(({ auth, common }) => {
    return {
      user: auth.user,
      clients: common.clients,
      getClientsLoading: common.getClientsLoading,
      selectedClient: common.selectedClient,
    };
  });

  const renderTitle = () => {
    if (title) {
      return title;
    } else {
      return sayHi();
    }
  };
  const renderSubTitle = () => {
    if (subTitle) {
      return subTitle;
    } else {
      return subTitleToTitle ? sayHi() : "";
    }
  };

  const sayHi = () => {
    return "List of All Employees, you can update/upload excel or pdf file to update employees data";
    // return `Hi ${reduxState?.user?.name}!`
  };

  const menu = (obj) => {
    return (
      <Menu className="header-dropdown">
        <Menu.Item key="0" onClick={() => null}>
          Update
        </Menu.Item>
        <Menu.Item key="1"> Share with all students </Menu.Item>
        <Menu.Item key="2"> Share with whole class </Menu.Item>
        <Menu.Item key="3"> Share with... </Menu.Item>
      </Menu>
    );
  };

  const goBack = () => {
    if (onClickGoBack) {
      onClickGoBack();
    } else {
      navigate(-1);
    }
  };

  const renderGoBack = () => {
    return <ImArrowLeft2 />; //<CIcon icon='thin-arrow-left' className='c-icon' onClick={goBack} />
  };

  const renderCompany = () => {
    return <CField
      containerClass="company-drop"
      placeholder="Company Name"
      isLabel={false}
      value={reduxState?.selectedClient}
      disabled={reduxState?.getClientsLoading || companyLoading}
      loading={reduxState?.getClientsLoading}
      data={reduxState?.clients}
      fieldType="select"
      renderSelectedKey='companyName'
      onChange={(val) => dispatch(changeClient(val))}
    />
  }

  return (
    <div
      className="navbar"
      style={{
        background:
          navbarBg,
      }}
    >
      <button
        className="menu-button"
        onClick={() => dispatch(toggleLeftDrawer(true))}
      >
        <IoIosMenu />
      </button>
      {canGoBack && (
        <button className="back-button" onClick={goBack}>
          {renderGoBack()}
        </button>
      )}
      <div className="main-nav">
        <div className="navbar-left">
          <h1>{subTitleToTitle ? renderSubTitle() : renderTitle()}</h1>
          {renderSubTitle() && <p>{renderSubTitle()}</p>}
        </div>
        {renderRight() ? <div className="navbar-right">{renderRight()}</div> : null}
        {canRenderCompany ? renderCompany() : null}
      </div>
      {renderBelow() ? <div>{renderBelow()}</div> : null}
    </div>
  );
}
export default React.memo(Navbar);
