import EXCHANGE_HOUSE from "../constants/ExchangeHouse.constant";

const initialState = {
    getLoading: false,
    exchangeHouses: [],
    exchangeHouseMetaData: {},
    addExchangeLoading: false,
    syncLoading: false,

    syncLoading: false,

    updateExchangeHouseLoading: false,
};

export default (state = initialState, action = {}) => {
    switch (action.type) {

        case EXCHANGE_HOUSE.GET_EXCHANGE_HOUSE:
            return {
                ...state,
                getLoading: action.loading,
                exchangeHouses: action.data || state.exchangeHouses,
                exchangeHouseMetaData: action.metaData || state.exchangeHouseMetaData
            };

        case EXCHANGE_HOUSE.ADD_EXCHANGE_HOUSE:
            return {
                ...state,
                addExchangeLoading: action.loading,
            };

        case EXCHANGE_HOUSE.SYNC_EXCHANGE_HOUSES:
            return {
                ...state,
                syncLoading: action.loading,
            };

        case EXCHANGE_HOUSE.UPDATE_EXCHANGE_HOUSE:
            return {
                ...state,
                updateExchangeHouseLoading: action.loading,
            };

        default:
            return state;
    }
};
