import { TOKEN, USER } from "./Constants";
import axios from "axios";

export const setValueInToLocalStorage = async (key, data) => {
    try {
        await localStorage.setItem(key, data);
    } catch (error) {
        console.log('error', error)
    }
};

//remove token from local storage
export const clearLocalstorage = () => {
    localStorage.removeItem(USER);
    localStorage.removeItem(TOKEN);
    localStorage.removeItem('authorization');
    delete axios.defaults.headers.common['authorization'];
};

export const getValueIntoLocalStorage = async (key) => {
    try {
        const value = await localStorage.getItem(key);
        if (value !== null) {
            return value
        } else {
            return null
        }
    } catch (error) {
        // Error retrieving data
        return null
    }
};

export const clearStorageData = () => {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(USER);
};

export const setValueIntoHeaders = (value) => {
    axios.defaults.headers.common['clientId'] = value;
}