import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CButton } from "../../uiComponents";
import { checkAdvanceSalaryEligibility } from "../../store/actions/Employees.action";
import { IoIosCash } from "react-icons/io";
import { ConditionalRendering } from "../../utils/methods";
import MainModal from './MainModal';
import { MOBILE_USER_STATUS } from "../../utils/constants";

const CApplyAdvanceSalary = ({ employee, client, mobileUser }) => {

    const dispatch = useDispatch();

    const reduxState = useSelector(({ employees }) => {
        return {
            loading: employees.checkAdvanceSalaryEligibilityLoading,
        };
    });

    const { loading } = reduxState;

    const [isOpen, setIsOpen] = useState(false);

    const checkEligibilityCallBack = () => {
        setIsOpen(true);
    }

    const checkAdvSalaryEligibility = () => {
        const payload = {
            employeeId: employee?._id,
            client: client?._id,
            force: true,
        }
        dispatch(checkAdvanceSalaryEligibility(payload, checkEligibilityCallBack));
    }

    return <>
        <ConditionalRendering
            condition={mobileUser?.status === MOBILE_USER_STATUS?.ACTIVE && !mobileUser?.isLightUser}
            children={<CButton
                containerClass="flex0"
                buttonType="gradient"
                title="Apply Advance Salary"
                disabled={loading}
                loading={loading}
                onClick={checkAdvSalaryEligibility}
                leftIcon={(className) => <IoIosCash className={className} />}
            />}
        />
        <MainModal isOpen={isOpen} setIsOpen={setIsOpen} employee={employee} client={client}  />
    </>
};

export default CApplyAdvanceSalary;