import React from "react";
import { Modal } from "antd";
import { Form, Formik } from "formik";
import { schemaPhone } from "./Validations";
import { CButton, CField } from "../../uiComponents";

const AddPhone = ({ isOpen, setIsOpen, getOTP, loading }) => {

    return (
        <Modal
            title="Enter Mobile Number"
            width={450}
            centered
            open={isOpen}
            maskClosable={false}
            closable={!loading}
            onCancel={() => !loading && setIsOpen(false)}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose
        >
            <Formik
                validationSchema={schemaPhone}
                validateOnChange={true}
                validateOnBlur={true}
                initialValues={{}}
                onSubmit={getOTP}
            >
                {({ errors, touched, handleSubmit, setFieldValue, values, setFieldTouched, submitCount }) => (
                    <Form>
                        <CField
                            name="phoneNumber"
                            placeholder="Mobile Number"
                            prefix={<span>+971</span>}
                            type="text"
                            containerClass={"flex0"}
                            error={submitCount ? errors?.phoneNumber : touched?.phoneNumber && errors.phoneNumber}
                            onBlur={() => setFieldTouched("phoneNumber", true, true)}
                            disabled={loading}
                            value={values?.phoneNumber}
                            onChange={(e) => {
                                let phone = e.target.value;
                                let reg = /^0+/gi;
                                if (phone.match(reg)) {
                                    phone = phone.replace(reg, '');
                                }
                                setFieldValue("phoneNumber", phone)
                            }}
                        />

                        <CButton
                            containerClass="flex0"
                            onClick={handleSubmit}
                            type="submit"
                            htmlType="submit"
                            title={'Submit'}
                            loading={loading}
                            disabled={loading}
                        />
                    </Form>)}
            </Formik>
        </Modal>
    );
};
export default React.memo(AddPhone);