import React, { useCallback, useEffect, useState } from "react";
import { Descriptions, Image, Modal } from "antd";
import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import { ConditionalRendering, renderDOB } from "../../utils/methods";
import Webcam from "react-webcam";
import { CButton } from "..";
import { TbCameraOff } from "react-icons/tb";
import dayjs from "dayjs";

const ConfirmationUqudo = ({ isOpen, setIsOpen, data, submitUqudo, runUqudo }) => {

    const webcamRef = React.useRef(null);

    useEffect(() => {
        decodeData();
    }, [data]);

    const reduxState = useSelector(({ employees }) => {
        return {
            responseMobileUserData: employees.holdingMobileUserData,
            loading: employees.uploadUqudoImagesLoading || employees.lightUserToPortalUserLoading,
        };
    });

    const [decodedData, setDecodedData] = useState({});
    const [capturedImage, setCapturedImage] = useState('');
    const [hasWebcam, setHasWebcam] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isAllowChange, setIsAllowChange] = useState(false);

    const onCancel = () => {
        runUqudo();
        setIsOpen(false);
        setCapturedImage('');
    }

    const decodeData = () => {
        try {
            if (data) {
                const decoded = jwtDecode(data);
                setDecodedData(decoded?.data?.documents[0]?.scan);
            }

        } catch (error) {
            console.log('error', error)
        }
    }

    useEffect(() => {
        if (isOpen) {
            checkWebCam();
            navigator.mediaDevices.getUserMedia(
                {
                    video: true,
                },
            ).then((stream) => {
                setIsAllowChange(!isAllowChange)
            })
                .catch((error) => console.log('camera permission error', error));
        }
    }, [isOpen, isAllowChange]);

    const checkWebCam = () => {
        const enumartedDevices = navigator.mediaDevices.enumerateDevices();
        enumartedDevices.then(devices => {
            for (var d in devices) {
                if (devices[d]?.kind === 'videoinput' && devices[d].deviceId?.length)
                    setHasWebcam(true);
            }
        })

    }

    const videoConstraints = {
        height: 300,
        facingMode: "user"
    };

    const capture = useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            setCapturedImage(imageSrc);
        },
        [webcamRef]
    );

    const onCancelHandler = () => {
        setCapturedImage('');
        setIsOpen(false)
    }

    const submit = () => {
        if (capturedImage?.length) {
            setIsError(false);
            submitUqudo(capturedImage, data)
        } else {
            setIsError(true);
        }
    }

    return <>
        <Modal
            title="Verify Details"
            centered
            open={isOpen}
            destroyOnClose={true}
            maskClosable={false}
            closable={false}
            onCancel={() => !reduxState?.loading && onCancelHandler()}
            okButtonProps={{ title: 'Verify', onClick: submit, loading: reduxState?.loading }}
            okText='Verify'
            cancelButtonProps={{ onClick: onCancel, loading: reduxState?.loading }}
            cancelText='Re run'
        >
            <div className="d-flex col justify-center" >
                <ConditionalRendering
                    condition={hasWebcam}
                    children={<ConditionalRendering
                        condition={capturedImage}
                        children={<Image src={capturedImage} width={'90%'} className="margin-left_20 margin-right_20" preview={false} />}
                        elseChildren={<Webcam
                            ref={webcamRef}
                            audio={false}
                            height={300}
                            screenshotFormat="image/jpeg"
                            videoConstraints={videoConstraints}
                        />}
                    />}
                    elseChildren={<div className="no-camera-access">
                        <TbCameraOff size={36} />
                        <p>Camera is not accessible</p>
                    </div>}
                />
                {isError ? <p className="error" >Please add user image</p> : null}
                <CButton
                    containerClass="margin-left_20"
                    title="Capture User Image"
                    disabled={reduxState?.loading}
                    onClick={capture}
                />
                <CButton
                    containerClass="margin-left_20"
                    buttonLayout="primary-outline"
                    title="Clear Image"
                    disabled={reduxState?.loading}
                    onClick={() => setCapturedImage('')}
                />
            </div>

            <Descriptions title="" layout="horizontal" bordered column={1} >
                <Descriptions.Item label="Full Name">{decodedData?.front?.fullName}</Descriptions.Item>
                <Descriptions.Item label="Emirates Id">{decodedData?.front?.identityNumber}</Descriptions.Item>
                <Descriptions.Item label="Nationality">{decodedData?.front?.nationality}</Descriptions.Item>
                <Descriptions.Item label="DOB">{decodedData?.back?.dateOfBirthFormatted ? dayjs(decodedData?.back?.dateOfBirthFormatted).format("DD-MM-YYYY") : decodedData?.back?.dateOfBirth ? renderDOB(decodedData?.back?.dateOfBirth) : ''}</Descriptions.Item>
            </Descriptions>
        </Modal>
    </>
};

export default ConfirmationUqudo;