import React from "react";
import { Modal } from "antd";
import CField from "../cField/CField";
import OtpInput from "react-otp-input";

const OTPBypassEid = ({ isOpen, setIsOpen, otp }) => {

    return (
        <Modal
            width={450}
            centered
            open={isOpen}
            maskClosable={false}
            onCancel={() => setIsOpen(false)}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose
        >
            <h3 className="margin-bottom_zero" >Alternate Verify OTP</h3>
            <CField
                containerClass="margin-right_zero"
                className="otp-container"
                name="otp"
                disabled={true}
                customField={() => <OtpInput
                    className="otp-field lg-font"
                    isDisabled={true}
                    value={otp || '666666'}
                    numInputs={6}
                />}
            />
        </Modal>
    );
};
export default React.memo(OTPBypassEid);