import { FaHandHoldingUsd } from "react-icons/fa";
import { BsBank2 } from "react-icons/bs";
import { GiWallet } from "react-icons/gi";

export const icons = {
    BankAccount: <BsBank2 className="c-icon secondary" />,
    COTC: <FaHandHoldingUsd className="c-icon secondary" />,
    'Mobile Wallet': <GiWallet className="c-icon secondary" />,
    bank: <GiWallet className="c-icon secondary" />,
};

export const modifyData = (types, specialBanks) => {
    let array = [];
    array.push(...types);
    if (specialBanks?.length) {
        array.push(...specialBanks)
    }
    return array
};

export const isPakistan = (cca2) => cca2 === 'PK';
export const isIndia = (cca2) => cca2 === 'IN';

export const showNumberInBank = (type, country) => {
    let countries = ['IN'];
    return type === 'BankAccount' && (countries?.includes(country?.CCA2) || countries?.includes(country?.cca2) || countries?.includes(country?.iso))
};

export const wallets = ['JazzCash', 'Jazz Cash', 'Easy Paisa'];
export const phWallets = ['GCASH G XCHANGE INC'];