const ApprovalConstant = {
    GET_EH_APPROVALS: 'GET_EH_APPROVALS',

    EH_APPROVE_REJECT: 'EH_APPROVE_REJECT',

    GET_EH_EMPLOYEERS_LIST: 'GET_EH_EMPLOYEERS_LIST',

    GET_EH_SINGLE_APPROVAL: 'GET_EH_SINGLE_APPROVAL',
    
    UPLOAD_ESTABLISHMENTS: 'UPLOAD_ESTABLISHMENTS',

    UPDATE_EH_APPROVAL_SOCKET: 'UPDATE_EH_APPROVAL_SOCKET',
};

export default ApprovalConstant