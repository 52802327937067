import * as Yup from "yup";
import { getCountryObj, phoneValidateNumberRegex } from "../../utils/methods";

const schemaPhone = Yup.object().shape({
  phoneNumber: Yup.string()
    .label("Mobile Number")
    .test('checkPhoneNumber', (value, obj) => phoneValidateNumberRegex(getCountryObj('AE'), value || '', obj, 'Mobile number')),
});
const schemaOTP = Yup.object().shape({
  otp: Yup.string().label("OTP").required('Please enter user OTP code').min(6, 'Please enter user OTP code').max(6, 'Please enter user OTP code'),
});

export { schemaPhone, schemaOTP };