import React, { useRef } from "react";
import { Modal } from "antd";
import { Form, Formik } from "formik";
import scheme from "./Validations";
import { CButton, CField } from "../../uiComponents";
import { useDispatch } from "react-redux";
import { updateEmpCode } from "../../store/actions/Employees.action";

const EditForm = ({ isOpen, setIsOpen, loading, employee, client }) => {

    const dispatch = useDispatch();
    const form = useRef(null);

    const onCancel = () => {
        if (!loading) {
            setIsOpen(false);
        }
    };

    const callBack = () => {
        form.current.resetForm();
        setIsOpen(false);
    };

    const submit = (values) => {
        const payload = {
            ...values,
            employee: employee?._id,
            client
        }
        dispatch(updateEmpCode(payload, callBack));
    }

    return (
        <Modal
            title={"Edit Emp Code"}
            width={450}
            centered
            open={isOpen}
            maskClosable={false}
            onCancel={onCancel}
            cancelButtonProps={{ hidden: true }}
            okButtonProps={{ hidden: true }}
            destroyOnClose
        >
            <Formik
                innerRef={form}
                validationSchema={scheme}
                validateOnChange={true}
                validateOnBlur={true}
                initialValues={{ empCode: employee?.empCode }}
                onSubmit={submit}
            >
                {({ errors, touched, handleSubmit, values, setFieldTouched, submitCount, handleChange }) => (
                    <Form>
                        <CField
                            name="empCode"
                            placeholder="Emp Code"
                            type="text"
                            containerClass={"flex0"}
                            error={submitCount ? errors?.empCode : touched?.empCode && errors.empCode}
                            onBlur={() => setFieldTouched("empCode", true, true)}
                            disabled={loading}
                            value={values?.empCode}
                            onChange={(e) => handleChange('empCode')(e.target.value)}
                        />

                        <CButton
                            containerClass="flex0"
                            onClick={handleSubmit}
                            type="submit"
                            htmlType="submit"
                            title={'Submit'}
                            loading={loading}
                            disabled={loading}
                        />
                    </Form>)}
            </Formik>

        </Modal>
    );
};
export default React.memo(EditForm);