const AUTH = {

    LOGIN_USER_API: 'LOGIN_USER_API',

    SIGNUP_USER_API: 'SIGNUP_USER_API',

    SET_OTP_AUTH : 'SET_OTP_AUTH' , 

    GEN_USER_OTP : 'GEN_USER_OTP ' , 

    RESEND_OTP_API : 'RESEND_OTP_API',

    CHECK_OTP: 'CHECK_OTP',

    RESET_PASSWORD_API: 'RESET_PASSWORD_API',

    GET_USER_PROFILE: 'GET_USER_PROFILE',

    LOGOUT_USER_API: 'LOGOUT_USER_API',

    GET_TRANSACTION: 'GET_TRANSACTION',

    GET_BALANCE: 'GET_BALANCE',

    FORGET_API: 'FORGET_API',

    UPDATE_PROFILE: 'UPDATE_PROFILE',

    CHANGE_PASSWORD: "CHANGE_PASSWORD",

    UPDATE_DOCS: 'UPDATE_DOCS',

    MAIL_RECIEVER_TOGGLE: 'MAIL_RECIEVER_TOGGLE',

    TOPIC_STEPPER_UPDATE: 'TOPIC_STEPPER_UPDATE',

    REFRESH_TOKEN: "REFRESH_TOKEN",

    UPDATE_NOTIFICATION_PREFERENCE_API : 'UPDATE_NOTIFICATION_PREFERENCE_API',

};

export default AUTH;
