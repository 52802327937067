import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addbeneficiary, getOTPBeneficiary } from "../../../store/actions/FindCardHolder.action";
import { Form, Formik } from "formik";
import { scheme } from "./Validations";
import { CButton, CField } from "../../../uiComponents";
import { countryData } from "../../../utils/countryData";
import _ from "lodash";
import { ConditionalRendering } from "../../../utils/methods";
import { phWallets, showNumberInBank, wallets } from "./helper";
import OTPConfirmation from "./OTPConfirmation";

const InputForm = ({ selectedData, callBack, client, employee }) => {

    const dispatch = useDispatch();
    const form = useRef(null);

    const reduxState = useSelector(({ findCardHolder }) => {
        return {
            data: findCardHolder?.branches,
            loading: findCardHolder?.getBranchesLoading,
            metaData: findCardHolder?.branchesMetaData,
            loadMoreLoading: findCardHolder?.BranchesLoadMoreLoading,
            canLoadMore: findCardHolder?.BranchesCanLoadMore,
            selectedBeneficiaryCountry: findCardHolder?.selectedBeneficiaryCountry,
            selectedBank: findCardHolder.selectedBank,
            addLoading: findCardHolder?.addBeneficiaryLoading,
            getOTPBeneficiaryLoading: findCardHolder.getOTPBeneficiaryLoading,
        };
    });

    const { loading, addLoading, getOTPBeneficiaryLoading } = reduxState;

    const [selectedCountry, updateSelectedCountry] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [payload, setPayload] = useState({});
    const [holdingResponse, setHoldingResponse] = useState({});

    useEffect(() => {
        const found = countryData?.find(country => (country?.iso === selectedData?.country?.CCA2 || country?.iso === selectedData?.country?.cca2));
        found['dCode'] = found?.code;
        found['dCode'] = found?.dCode.replace('+', '');
        updateSelectedCountry({ ...selectedData?.country, ...found });
        return form.current?.resetForm();
    }, []);

    const getFullName = (obj = {}) => {
        let firstName = obj?.beneficiaryFirstName || form?.current?.values?.beneficiaryFirstName;
        let lastName = obj?.beneficiaryLastName || form?.current?.values?.beneficiaryLastName;
        return `${firstName} ${lastName}`;
    };
    const setBeneficiaryName = () => {
        let currentValue = form?.current?.values?.addBeneficiary;
        form.current.setFieldValue('alias', currentValue ? getFullName() : '');
    };

    const setAccountTitle = () => {
        if (selectedData?.bankType?.Value === 'BankAccount') {
            form.current.setFieldValue('accountTitle', getFullName());
        }
        setBeneficiaryName();
    };

    const setAccountNumber = () => {
        if (selectedData?.bankType?.Value === 'BankAccount') {
            let currentValue = form?.current?.values?.beneficiaryMSISDN;
            if (wallets.includes(selectedData?.BankName) || phWallets.includes(selectedData?.BankName)) {
                form.current.setFieldValue('beneficiaryAccountNo', `0${currentValue}`);
            }
        }
        setBeneficiaryName();
    };

    const checkWallet = () => {
        if ((selectedData?.country?.CCA2 === "PH" || selectedData?.country?.cca2 === "PH") && phWallets.includes(selectedData?.BankName)) {
            return {
                value: '',
                placeholder: '09XXXXXXXXX',
            }
        } else if (wallets.includes(selectedData?.BankName)) {
            return {
                value: '',
                placeholder: '03XXXXXXXXX',
            }
        } else {
            return {
                value: selectedData?.IBAN || '',
                placeholder: '',
            }
        }
    };

    const onSubmit = (values) => {
        const requestPayload = {
            ...values,
            ...payload,
            ...holdingResponse,
        }
        dispatch(addbeneficiary(requestPayload, callBack));
    }

    const getOTPCallback = (res) => {
        setHoldingResponse(res);
        setIsOpen(true);
    }

    const onFinish = (val) => {
        const requestPayload = {
            bankId: selectedData?.bank?._id,
            ...val,
            beneficiaryNationalityCountryISOCode: val?.beneficiaryNationalityCountryISOCode?.CCA3,
            ...(showNumberInBank(selectedData?.bankType?.Value, selectedCountry) || selectedData?.bankType?.Value !== 'BankAccount' ? {
                beneficiaryMSISDN: `${selectedCountry?.dCode}${val.beneficiaryMSISDN.replace(/\s+/g, '')}`,
            } : {
                beneficiaryMSISDN: `${selectedCountry?.dCode}00000000000`,
            }),
        };
        setPayload(requestPayload);
        const otppayload = {
            client: client?._id,
            employeeId: employee?._id
        }
        dispatch(getOTPBeneficiary(otppayload, getOTPCallback))
    }

    const submit = () => {
        setAccountNumber();
        setAccountTitle();
        form.current?.handleSubmit();
    }

    return (
        <>
            <Formik
                innerRef={form}
                initialValues={{
                    beneficiaryFirstName: '',
                    beneficiaryLastName: '',
                    ...(selectedData?.bankType?.Value === 'BankAccount' ? {
                        accountTitle: '',
                        beneficiaryAccountNo: checkWallet().value,
                    } : {
                        beneficiaryMSISDN: ``,
                    }),
                    beneficiaryNationalityCountryISOCode: selectedData?.country,
                    remitPurpose: 'FAMILY',
                    addBeneficiary: true
                }}
                validationSchema={scheme(selectedData?.bankType?.Value, selectedCountry)}
                onSubmit={onFinish}
            >
                {({ errors, touched, handleSubmit, setFieldValue, values, setFieldTouched, submitCount, handleChange }) => (
                    <Form className="inner-form col flex0">
                        <CField
                            containerClass='margin-right_zero'
                            disabled={loading}
                            name="beneficiaryFirstName"
                            placeholder="First Name"
                            type="text"
                            value={values?.beneficiaryFirstName}
                            onChange={(e) => handleChange("beneficiaryFirstName")(e.target.value)}
                            onBlur={() => setFieldTouched("beneficiaryFirstName", true, true)}
                            error={submitCount ? errors?.beneficiaryFirstName : touched?.beneficiaryFirstName && errors.beneficiaryFirstName}
                        />

                        <CField
                            containerClass='margin-right_zero'
                            disabled={loading}
                            name="beneficiaryLastName"
                            placeholder="Last Name"
                            type="text"
                            value={values?.beneficiaryLastName}
                            onChange={(e) => handleChange("beneficiaryLastName")(e.target.value)}
                            onBlur={() => setFieldTouched("beneficiaryLastName", true, true)}
                            error={submitCount ? errors?.beneficiaryLastName : touched?.beneficiaryLastName && errors.beneficiaryLastName}
                        />

                        <ConditionalRendering
                            condition={showNumberInBank(selectedData?.bankType?.Value, selectedCountry) || selectedData?.bankType?.Value !== 'BankAccount'}
                            children={<CField
                                name="beneficiaryMSISDN"
                                placeholder="Phone Number"
                                type="text"
                                prefix={<span>{selectedCountry?.code}</span>}
                                containerClass='w-100 flex-auto margin-right_zero'
                                error={submitCount ? errors?.beneficiaryMSISDN : touched?.beneficiaryMSISDN && errors.beneficiaryMSISDN}
                                onBlur={() => setFieldTouched("beneficiaryMSISDN", true, true)}
                                value={values?.beneficiaryMSISDN}
                                onChange={(e) => {
                                    let phone = e.target.value;
                                    let reg = /^0+/gi;
                                    if (phone.match(reg)) {
                                        phone = phone.replace(reg, '');
                                    }
                                    setFieldValue("beneficiaryMSISDN", phone)
                                }}
                            />}
                        />

                        <ConditionalRendering
                            condition={selectedData?.bankType?.Value === 'BankAccount'}
                            children={<CField
                                containerClass='margin-right_zero'
                                disabled={loading}
                                name="beneficiaryAccountNo"
                                placeholder={checkWallet().placeholder}
                                isLabel='IBAN / Account Number'
                                type="text"
                                value={values?.beneficiaryAccountNo}
                                onChange={(e) => handleChange("beneficiaryAccountNo")(e.target.value)}
                                onBlur={() => setFieldTouched("beneficiaryAccountNo", true, true)}
                                error={submitCount ? errors?.beneficiaryAccountNo : touched?.beneficiaryAccountNo && errors.beneficiaryAccountNo}
                            />}
                        />

                        <CButton
                            containerClass='margin-right_zero'
                            type="submit"
                            htmlType="submit"
                            onClick={() => submit()}
                            title={`Submit`}
                            buttonLayout="primary"
                            loading={getOTPBeneficiaryLoading}
                            disabled={addLoading || getOTPBeneficiaryLoading}
                        />
                    </Form>
                )}
            </Formik>
            <OTPConfirmation isOpen={isOpen} setIsOpen={setIsOpen} employee={employee} onSubmit={onSubmit} holdingResponse={holdingResponse} setHoldingResponse={setHoldingResponse} />
        </>
    );
};
export default React.memo(InputForm);