import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, List, Skeleton } from "antd";
import { getBanks } from "../../../store/actions/FindCardHolder.action";
import { readableTextCap, searchView } from "../../../utils/methods";
import InfiniteScroll from "react-infinite-scroll-component";
import { icons } from "./helper";
import { debounce } from "lodash";

const SelectBank = ({ selectedData, onSelect }) => {

    const dispatch = useDispatch();

    const reduxState = useSelector(({ findCardHolder }) => {
        return {
            data: findCardHolder?.banks,
            loading: findCardHolder?.getBanksLoading,
            metaData: findCardHolder?.banksMetaData,
            loadMoreLoading: findCardHolder?.banksLoadMoreLoading,
            canLoadMore: findCardHolder?.banksCanLoadMore,

            branches: findCardHolder?.branches,
            branchesLoading: findCardHolder?.getBranchesLoading,
        };
    });

    const { data = [], loading, metaData, loadMoreLoading, canLoadMore, branchesLoading } = reduxState;

    const [page, setPage] = useState(1);
    const [limit] = useState(20);
    const [search, setSearch] = useState('');

    useEffect(() => {
        get(page);
    }, [page, search]);

    const filtersDebounce = useCallback(
        debounce((e) => {
            try {
                dispatch(getBanks(e));
            } catch (error) { }
        }, 800),
        []
    );

    const get = (val) => {
        const payload = {
            page: val || 1,
            limit,
            country: selectedData?.country?.Name,
            bankType: selectedData?.bankType?.BankType ? selectedData?.bankType?.BankType : selectedData?.bankType?.Value,
            ...(search && { search })
        };
        if (search?.length) {
            filtersDebounce(payload)
        } else {
            dispatch(getBanks(payload));
        }
    };

    const next = () => {
        if (metaData?.totalDocuments > data?.length) {
            setPage(page + 1)
        }
    };

    const onSearch = (val) => {
        setSearch(val);
        setPage(1);
    };

    return (
        <>
            {searchView(search, onSearch, 'margin-top_15 margin-bottom_15')}

            <InfiniteScroll
                className={`infinite-scroll ${loading && 'loading'}`}
                dataLength={data?.length || 0}
                next={next}
                height={400}
                hasMore={canLoadMore}
                loader={loadMoreLoading && canLoadMore && <Skeleton className="margin-top_15 margin-left_20"
                    avatar
                    round={false}
                    paragraph={{ rows: 0 }}
                    active
                />}
            >
                <List
                    dataSource={data}
                    loading={loading || branchesLoading}
                    loadMore={loadMoreLoading}
                    className="ant-list-item-countries"
                    renderItem={(item) => {
                        let icon = icons['bank'];
                        let title = item?.BankName;
                        return <List.Item
                            key={item?.CCA2}
                            onClick={() => onSelect(item, 3)}
                        >
                            <List.Item.Meta
                                avatar={<Avatar shape="square" className="avatar-container" src={icon} />}
                                title={readableTextCap(title)}
                            />
                        </List.Item>
                    }}
                />
            </InfiniteScroll>
        </>
    );
};
export default React.memo(SelectBank);