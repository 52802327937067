const BNPL = {

    GET_ALL_CATEGORIES: 'GET_ALL_CATEGORIES',
    GET_CATEGORIES: 'GET_CATEGORIES',
    UPDATE_CATEGORY: 'UPDATE_CATEGORY',
    ADD_CATEGORY: 'ADD_CATEGORY',

    GET_ALL_SUB_CATEGORIES: 'GET_ALL_SUB_CATEGORIES',
    GET_SUB_CATEGORIES: 'GET_SUB_CATEGORIES',
    UPDATE_SUB_CATEGORY: 'UPDATE_SUB_CATEGORY',
    ADD_SUB_CATEGORY: 'ADD_SUB_CATEGORY',

    GET_PRODUCTS: 'GET_PRODUCTS',
    UPDATE_PRODUCT: 'UPDATE_PRODUCT',
    ADD_PRODUCT: 'ADD_PRODUCT',


    GET_ALL_SUPPLIERS: 'GET_ALL_SUPPLIERS',
    GET_SUPPLIERS: 'GET_SUPPLIERS',
    UPDATE_SUPPLIER: 'UPDATE_SUPPLIER',
    ADD_SUPPLIER: 'SUPPLIER',

    CREATE_PRODUCT_VARIATIONS: 'CREATE_PRODUCT_VARIATIONS',

    ADD_PRODUCT_IMAGES: 'ADD_PRODUCT_IMAGES',

    GET_PRODUCT_DETAIL: 'GET_PRODUCT_DETAIL',

    GET_ORDERS: 'GET_ORDERS',

    ASSIGN_SUPPLIER_TO_ORDER_PRODUCT: 'ASSIGN_SUPPLIER_TO_ORDER_PRODUCT',

    ADD_TRACKING_ID_TO_ORDER_PRODUCT: 'ADD_TRACKING_ID_TO_ORDER_PRODUCT',

    MARK_DELIVERED_TO_ORDER_PRODUCT: 'MARK_DELIVERED_TO_ORDER_PRODUCT',

    GET_SUPPLIERS_BY_PRODUCT: 'GET_SUPPLIERS_BY_PRODUCT',

    GET_PRODUCT_INVENTORIES: 'GET_PRODUCT_INVENTORIES',

    UPDATE_PRODUCT_INVENTORY: 'UPDATE_PRODUCT_INVENTORY',
};

export default BNPL;
