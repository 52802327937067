import ONBOARD_APPROVALS from "../constants/OnboardApprovals.constant";

const initialState = {

    getLoading: true,
    approvals: [],
    approvalsMetaData: {},

    approveRejectLoading: false,

    getSingleApprovalLoading: false,
    approval: {},

    employeersList: [],
    getEmployeersLoading: false,
};

export default (state = initialState, action = {}) => {
    switch (action.type) {

        case ONBOARD_APPROVALS.ON_GET_APPROVALS:
            return {
                ...state,
                getLoading: action.loading,
                approvals: action.data || state.approvals,
                approvalsMetaData: action.metaData || action.approvalsMetaData
            };

        case ONBOARD_APPROVALS.ON_APPROVE_REJECT:
            return {
                ...state,
                approveRejectLoading: action.loading,
            };

        case ONBOARD_APPROVALS.ON_GET_SINGLE_APPROVAL:
            return {
                ...state,
                getSingleApprovalLoading: action.loading,
                approval: action.data,
            };

        case ONBOARD_APPROVALS.GET_ON_EMPLOYEERS_LIST:
            return {
                ...state,
                employeersList: action.data,
                getEmployeersLoading: action.loading,
            };

        case ONBOARD_APPROVALS.UPDATE_ONBOARD_APPROVAL_SOCKET:
            return {
                ...state,
                approvals: action.data,
            };

        default:
            return state;
    }
};
