import React from 'react';

const styles = {
    container: {
        overflow: 'scroll',
        margin: 0,
        width: '100%',
        height: '100%',
        fontFamily: 'Poppins, sans-serif',
    },
    body: {
        margin: 0,
        display: 'flex',
        width: '100%',
        height: '100%',
        fontFamily: 'Poppins, sans-serif',
    },
    p: {
        margin: 0,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        marginTop: 20,
        marginInline: 0,
        marginBottom: 15,
    },
    logoContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'flex-start',
    },
    logoContainerImg: {
        width: 200,
        height: 100,
        objectFit: 'contain',
    },
    contactInfoList: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: '5px',
    },
    contactInfoListItem: {},
    contactInfoListItemSpan: {
        fontSize: '12px',
        color: '#000000',
    },
    contactInfoListItemSpanB: {
        color: '#ec6f30',
    },
    table: {
        width: '100%',
        padding: '35px',
    },
    headerRow: {
        verticalAlign: 'top',
    },
    contentCell: {
        padding: '10px',
        border: '1px solid black',
    },
    kfcTitle: {
        fontSize: '14px',
        lineHeight: '20px',
        textAlign: 'center',
        fontWeight: '700',
        marginBottom: '15px',
    },
    warning: {
        fontSize: '12px',
        fontWeight: '700',
        color: 'red',
    },
    kfcParagraph: {
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: '400',
    },
    listDecimal: {
        listStyle: 'decimal',
        fontSize: '12px',
    },
    listDisc: {
        listStyle: 'disc',
        fontSize: '12px',
    },
};

const TermsAndConditions = () => {
    return (
        <table style={styles.table}>
            <tbody style={{ verticalAlign: 'top' }}>
                <tr style={styles.headerRow}>
                    <td>
                        <div style={{ ...styles.header }}>
                            <div style={{ ...styles.logoContainer }}>
                                <img
                                    src="https://kp-statics.s3.me-south-1.amazonaws.com/Ajman_bank_logo.png"
                                    alt="ajman-bank" style={styles.logoContainerImg}
                                />
                            </div>
                        </div>
                        <div style={styles.contentCell}>
                            <p style={styles.kfcTitle}>
                                TERMS & CONDITIONS – KAMEL PAY CARDHOLDERS SALARY ADVANCE FACILITY
                            </p>

                            <h3 style={styles.warning}>
                                Product Description:
                            </h3>
                            <p style={styles.kfcParagraph}>
                                Cardholder can avail Ajman Bank- Salary Advance which is temporary advance of up to maximum
                                50% of one month’s net salary and can be withdrawn from the card, or utilized to cover any
                                of Cardholder’s monthly financial obligations. This facility can be availed every month,
                                provided full payment for the amount received is made by the month-end or next salary credit
                                date depending in the facility date, noting that this product has been approved by the Bank
                                Sharia supervision committee.
                            </p>
                            <ul style={{ ...styles.kfcParagraph }}>
                                <li>
                                    <p style={styles.kfcParagraph}>
                                        Amount, shall be paid from the following Cardholder’s salary transferred to Kamel
                                        Pay Prepaid Card.
                                    </p>
                                </li>
                            </ul>
                            <h3 style={styles.warning}>
                                Terms & Conditions:
                            </h3>
                            <ul style={styles.listDecimal}>
                                <li>
                                    <p style={styles.kfcParagraph}>
                                        Salary Advance transaction is carried out in compliance with Islamic Sharia rules and principles, and it is based on Qard Al Hasan.
                                    </p>
                                </li>
                                <li>
                                    <p style={styles.kfcParagraph}>
                                        Ajman Bank is entitled, at its absolute discretion, to accept or reject Cardholder’s application based on Bank’s defined eligibility criteria.
                                    </p>
                                </li>
                                <li>
                                    <p style={styles.kfcParagraph}>
                                        If the facility application is approved, it will be, as per the below mentioned details:
                                    </p>
                                    <ul style={styles.listDisc}>
                                        <li>
                                            <p style={styles.kfcParagraph}>
                                                Maximum facility amount availed by the Cardholder under this service is AED 2,500.
                                            </p>
                                        </li>
                                        <li>
                                            <p style={styles.kfcParagraph}>
                                                Salary must be transferred to the Kamel Pay Prepaid Card regularly and from a known employer.
                                            </p>
                                        </li>
                                        <li>
                                            <p style={styles.kfcParagraph}>
                                                If Cardholder avails the facility on or before the 15th of the month, the utilized amount will be settled from Cardholder’s salary or at the end of the month, whichever comes first. If the date of availing the facility is after the 15th of the month, the utilized amount will be settled from Cardholder’s salary or at the end of the following month, whichever comes first.
                                            </p>
                                        </li>
                                        <li>
                                            <p style={styles.kfcParagraph}>
                                                Cardholder must be regular in paying his other obligations with Ajman Bank (If any).
                                            </p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p style={styles.kfcParagraph}>
                                        Other Terms & Conditions are to be considered after executing the transaction:
                                    </p>
                                    <ul style={styles.listDisc}>
                                        <li>
                                            <p style={styles.kfcParagraph}>
                                                Utilized amount has to be paid in the due date (As elaborated earlier in the Terms & Conditions), and if Cardholder fails to pay, <b><u>Ajman Bank may claim the unpaid amount through the legal procedures.</u></b>
                                            </p>
                                        </li>
                                        <li>
                                            <p style={styles.kfcParagraph}>
                                                If the Cardholder fails to pay the Utilized amount in the due date, <b><u>this may affect Cardholder credit rating, which may limit the Cardholder ability to another finance in the future</u></b> from Ajman Bank or other banks.
                                            </p>
                                        </li>
                                        <li>
                                            <p style={styles.kfcParagraph}>
                                                In the event of no balance on the due date in Cardholder’s salary account Ajman Bank has the right to deduct the due amount from any other credit account with the bank.
                                            </p>
                                        </li>
                                        <li>
                                            <p style={styles.kfcParagraph}>
                                                As mentioned in the service description, salary is the main security required to obtain this service, so the Cardholder must transfer the salary of the month following receiving the product, and in case salary is not transferred or Cardholder have been terminated from his work or left or changed his work, Cardholder’s end-of-service benefits must be transferred to his prepaid card with Kamelpay, noting that the <b><u>Ajman Bank is entitled to block the EOSB and any other amounts available in the prepaid card in order to pay any overdue amount(s)</u></b>.
                                            </p>
                                        </li>
                                        <li>
                                            <p style={styles.kfcParagraph}>
                                                The Cardholder shall have the right to have a Cooling-Off period, of 5 complete business days commencing from the time of signing the contract for the Salary Advance, hence if the Cardholder opted to terminate the contract within the cooling off period, Ajman Bank has to refund to it any related Fees and direct costs already incurred by the Cardholder. However, if the Cardholder, within the cooling off period, disposed of/acted on the subject of the contract in any manner, its right in the cooling off period will cease. For more information, please review the relevant Form issued along with the Salary Advance approval.
                                            </p>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            {/* Remaining content */}
                            <p style={styles.kfcParagraph}>
                                I hereby declare that I have read and understood the above-mentioned facts, and
                                undertake to comply and observe the same.
                            </p>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

const TermsAndConditionReceipt = () => {

    return (
        <div style={styles.container} >
            <TermsAndConditions />
        </div>
    );
};

export default TermsAndConditionReceipt;