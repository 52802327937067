import ScratchedCardConstant from "../constants/ScratchedCard.constant";

const initialState = {
    scratchedCards: [],
    getScratchedCardLoading: false,
    scratchedCardMetaData: {},
    scratchedCardStats : [],
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case ScratchedCardConstant.GET_SCRATCHED_CARD:
            return {
                ...state,
                scratchedCards: action.data,
                getScratchedCardLoading: action.loading,
                scratchedCardMetaData: action.metaData,
                scratchedCardStats : action.statsCount
            };

        default:
            return state;
    }
};